import Icon, { Props as IconProps } from 'hew/Icon';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AvatarCard from 'components/AvatarCard';
import { useUser } from 'contexts/User';
import { handlePath, paths } from 'routes/utils';
import { AnyMouseEvent } from 'utils/routes';
import { getDisplayName } from 'utils/user';

import ActionSheet from './ActionSheet';
import Link, { Props as LinkProps } from './Link';
import css from './NavigationTabbar.module.scss';

interface ToolbarItemProps extends LinkProps {
  badge?: number;
  icon: string;
  label: string;
  status?: string;
}

const ToolbarItem: React.FC<ToolbarItemProps> = ({ path, status, ...props }: ToolbarItemProps) => {
  const location = useLocation();
  const classes = [css.toolbarItem];
  const [isActive, setIsActive] = useState(false);

  if (isActive) classes.push(css.active);

  useEffect(() => setIsActive(location.pathname === path), [location.pathname, path]);

  return (
    <Link className={classes.join(' ')} path={path} {...props}>
      <Icon name={props.icon as IconProps['name']} size="large" title={props.label} />
      {status && <div className={css.status}>{status}</div>}
    </Link>
  );
};

const NavigationTabbar: React.FC = () => {
  const user = useUser();
  const [isShowingOverflow, setIsShowingOverflow] = useState(false);

  const handleOverflowOpen = useCallback(() => setIsShowingOverflow(true), []);
  const handleActionSheetCancel = useCallback(() => setIsShowingOverflow(false), []);

  const handlePathUpdate = useCallback((e: AnyMouseEvent, path: string) => {
    handlePath(e, { path });
    setIsShowingOverflow(false);
  }, []);

  const menuItems = [
    { icon: 'cluster', label: 'Clusters', path: paths.clusters(), showTooltip: true },
    { icon: 'user', label: 'Members', path: paths.members(), showTooltip: true },
    { icon: 'settings', label: 'Organization', path: paths.organization(), showTooltip: true },
    { icon: 'overflow-vertical', label: 'Overflow Menu', onClick: handleOverflowOpen },
  ];

  return (
    <nav className={css.base}>
      <div className={css.toolbar}>
        {menuItems.map((m) => {
          return (
            <ToolbarItem
              icon={m.icon}
              key={m.label + m.path}
              label={m.label}
              path={m.path}
              onClick={m.onClick}
            />
          );
        })}
      </div>
      <ActionSheet
        actions={[
          {
            render: () => (
              <div key="avatar-card">
                <AvatarCard className={css.user} text={getDisplayName(user)} />
              </div>
            ),
          },
          {
            icon: 'user',
            label: 'Sign out',
            onClick: (e) => handlePathUpdate(e, paths.logout()),
          },
          { external: true, icon: 'docs', label: 'Docs', path: paths.docs(), popout: true },
          { external: true, icon: 'info', label: 'Privacy', path: paths.privacy(), popout: true },
          {
            external: true,
            icon: 'pencil',
            label: 'Feedback',
            path: paths.feedback(),
            popout: true,
          },
        ]}
        show={isShowingOverflow}
        onCancel={handleActionSheetCancel}
      />
    </nav>
  );
};

export default NavigationTabbar;
