import { Rule } from 'antd/es/form';
import Form from 'hew/Form';
import Input from 'hew/Input';
import React from 'react';

import Link from 'components/Link';
import { AWSBackendProviderFields, GKEBackendProviderFields } from 'types';
import { validateOrgName } from 'utils/validate';

import css from './OrgFormItems.module.scss';

interface FormItemProps {
  rules?: Rule[] | undefined;
  currentOrgs?: string[];
}

export const AwsCrossAccountRoleItem: React.FC<FormItemProps> = ({ rules }) => {
  return (
    <>
      <Form.Item
        extra={
          <div>
            Specify the <b>ARN</b> of the cross-account role for your organization&apos;s AWS
            account. For more information, see the&nbsp;
            <Link path="docs/cloud-provider.html" popout={true}>
              User Manual.
            </Link>
          </div>
        }
        label="Cross-Account Role"
        required={true}>
        <div className={css.entry}>
          <Form.Item name={AWSBackendProviderFields.XAcctRole} noStyle rules={rules}>
            <Input autoComplete="off" min={1} type="text" />
          </Form.Item>
        </div>
      </Form.Item>
    </>
  );
};

export const AwsMasterInstanceProfile: React.FC<FormItemProps> = ({ rules }) => {
  return (
    <>
      <Form.Item
        extra={
          <div>
            Specify the <b>ARN</b> of the instance profile for the master node. For more
            information, see the&nbsp;
            <Link path="docs/cloud-provider.html" popout={true}>
              User Manual.
            </Link>
          </div>
        }
        label="Master Instance Profile"
        required={true}>
        <div className={css.entry}>
          <Form.Item name={AWSBackendProviderFields.MasterInstanceProfile} noStyle rules={rules}>
            <Input autoComplete="off" min={1} type="text" />
          </Form.Item>
        </div>
      </Form.Item>
    </>
  );
};

export const AwsAgentInstanceProfile: React.FC<FormItemProps> = ({ rules }) => {
  return (
    <>
      <Form.Item
        extra={
          <div>
            Specify the <b>ARN</b> of the instance profile for the agent nodes. For more
            information, see the&nbsp;
            <Link path="docs/cloud-provider.html" popout={true}>
              User Manual.
            </Link>
          </div>
        }
        label="Agent Instance Profile"
        required={true}>
        <div className={css.entry}>
          <Form.Item name={AWSBackendProviderFields.AgentInstanceProfile} noStyle rules={rules}>
            <Input autoComplete="off" min={1} type="text" />
          </Form.Item>
        </div>
      </Form.Item>
    </>
  );
};

export const GcpProjectId: React.FC = () => {
  return (
    <>
      <Form.Item
        extra={
          <div>
            Specify the GCP Project ID you have granted HPE MLDES access to. For more information on
            Project ID requirements, see the&nbsp;
            <Link path="docs/cloud-provider.html#gcp" popout={true}>
              Cloud Provider Documentation.
            </Link>
          </div>
        }
        label="GCP Project"
        name={GKEBackendProviderFields.ProjectId}
        rules={[
          { message: 'Project ID must be at least 6 characters', min: 6 },
          { max: 30, message: 'Project ID must be at most 30 characters' },
          { message: 'Field required', required: true },
        ]}>
        <Input autoComplete="off" min={1} type="text" />
      </Form.Item>
    </>
  );
};

export const OrgName: React.FC<FormItemProps> = ({ currentOrgs }) => {
  return (
    <>
      <Form.Item
        extra="Human-readable organization name"
        label="Name"
        name="name"
        rules={[
          { message: 'Organization name required', required: true },
          {
            validator: (_, value) => {
              if (currentOrgs?.includes(value)) {
                return Promise.reject('An organization with the same name already exists!');
              } else if (validateOrgName(value) || value.length === 0) {
                return Promise.resolve();
              } else {
                return Promise.reject('Invalid organization name');
              }
            },
          },
        ]}>
        <Input autoComplete="off" min={1} type="text" />
      </Form.Item>
    </>
  );
};
