import * as GlobalApi from 'services/global-bindings';
import * as RegionalApi from 'services/regional-bindings';

/**
 * TODO(DET-7323): stopgap for a backend-defined enum
 * @description valid access levels for an org user.
 */

export interface ModelOrgUserWithOverrides extends GlobalApi.ModelOrgUser {
  overrides?: { [key: string]: string };
}

export type ValueOf<T> = T[keyof T];
export const ClusterRole = {
  Admin: 'admin',
  None: 'none',
  User: 'user',
};

export type ClusterRole = ValueOf<typeof ClusterRole>;

export const availableClusterRoles = Object.values(ClusterRole);

export const OrgRole = {
  Admin: 'admin',
  User: 'user',
};

export type OrgRole = ValueOf<typeof ClusterRole>;

export const availableOrgRoles = Object.values(OrgRole);

export type RoleType = OrgRole | ClusterRole;

export interface OrgRoles {
  /** maps cluster id to access level */
  clusterRoles: Record<ClusterID, ClusterRole>;
  defaultClusterRole: ClusterRole;
  /** saas role */
  role: OrgRole;
}

export type Roles = Record<OrgID, OrgRoles>;

export type ClusterID = string;
export type OrgID = string;

/**
 * TODO(DET-7323): stopgap for a backend-defined enum
 * @description cluster states.
 * https://github.com/determined-ai/saas/blob/59b9005f332b2b7e7089fd47bee34cdf77841f75
 * /common/model/model.go#L196-L210
 * 	// Transitional cluster states
 * CREATING       ClusterState = "creating"
 * DELETING       ClusterState = "deleting"
 * PAUSING        ClusterState = "pausing"
 * RESUMING       ClusterState = "resuming"
 * RECONFIGURING  ClusterState = "reconfiguring"
 * UPGRADING      ClusterState = "upgrading"
 * REPROVISIONING ClusterState = "reprovisioning"
 * RESTORE_DB     ClusterState = "restoring database"
 * RENEWING_TLS   ClusterState = "renewing TLS certificate"
 *
 *	 // Failed cluster states
 * CREATE_FAILED      ClusterState = "creating failed"
 * DELETE_FAILED      ClusterState = "deleting failed"
 * PAUSE_FAILED       ClusterState = "pausing failed"
 * RESUME_FAILED      ClusterState = "resuming failed"
 * RECONFIGURE_FAILED ClusterState = "reconfiguring failed"
 * UPGRADE_FAILED     ClusterState = "upgrading failed"
 * REPROVISION_FAILED ClusterState = "reprovisioning failed"
 * RESTORE_DB_FAILED  ClusterState = "restoring database failed"
 * RENEW_TLS_FAILED   ClusterState = "renewing tls failed"

 *  // Legacy state
 * ACTION_FAILED ClusterState = "action failed"
 */
export enum ClusterState {
  // Transitional cluster states
  Creating = 'creating',
  Deleting = 'deleting',
  Pausing = 'pausing',
  Resuming = 'resuming',
  Reconfiguring = 'reconfiguring',
  Upgrading = 'upgrading',
  Reprovisioning = 'reprovisioning',
  RestoringDb = 'restoring database',
  RenewingTls = 'renewing TLS certificate',
  // Stable cluster states,
  Running = 'running',
  Paused = 'paused',
  Deleted = 'deleted',
  // Failed cluster states,
  CreateFailed = 'creating failed',
  DeleteFailed = 'deleting failed',
  PauseFailed = 'pausing failed',
  ResumeFailed = 'resuming failed',
  ReconfigureFailed = 'reconfiguring failed',
  UpgradeFailed = 'upgrading failed',
  ReprovisionFailed = 'reprovisioning failed',
  RestoreDbFailed = 'restoring database failed',
  RenewTlsFailed = 'renewing tls failed',
}
export const transitionalClusterState = new Set([
  ClusterState.Creating,
  ClusterState.Deleting,
  ClusterState.Pausing,
  ClusterState.Resuming,
  ClusterState.Reconfiguring,
  ClusterState.Upgrading,
  ClusterState.Reprovisioning,
  ClusterState.RestoringDb,
  ClusterState.RenewingTls,
]);

export const stableClusterState = new Set([
  ClusterState.Running,
  ClusterState.Paused,
  ClusterState.Deleted,
]);

export const failedClusterState = new Set([
  ClusterState.CreateFailed,
  ClusterState.DeleteFailed,
  ClusterState.PauseFailed,
  ClusterState.ResumeFailed,
  ClusterState.ReconfigureFailed,
  ClusterState.UpgradeFailed,
  ClusterState.ReprovisionFailed,
  ClusterState.RestoreDbFailed,
  ClusterState.RenewTlsFailed,
]);

export const upgradeableStates = new Set([ClusterState.Running]);

/**
 * Available options for generating a cluster
 * when using Basic config.
 */
export enum BasicConfigOption {
  Standard = 'standard',
  Pro = 'pro',
}

/**
 * Overall settings (not specific to resource pools)
 * when using Advanced config to create a cluster.
 */
export interface OverallConfig {
  detVersion: string;
  masterInstanceType: string;
}

interface Trackable {
  /** a unique key to track the object by for fast updates and rendering. */
  key: string;
}

/**
 * Settings for a resource pool
 * when using Advanced config to create a cluster.
 * A convenience wrapper around ModelResourcePoolConfig.
 */
export interface PoolConfig extends Trackable {
  acceleratorCount?: number;
  acceleratorType?: string;
  cpuSlotsAllowed: boolean;
  instanceType: string;
  maxIdleAgentPeriod?: string;
  maxInstances: number;
  minInstances?: number;
  poolName: string;
  primaryUse: ResourcePoolUse;
}

/**
 * Available options for the primary use of a resource pool.
 */
export enum ResourcePoolUse {
  Aux = 'aux',
  Compute = 'compute',
}

export interface ClusterDetails extends Omit<RegionalApi.ModelClusterDetails, 'masterConfig'> {
  masterConfig: Omit<RegionalApi.ModelMasterConfig, 'resource_pools'> & {
    resource_pools: PoolConfig[];
  };
}

export enum DefaultResourcePool {
  aux = 'default_aux_resource_pool',
  compute = 'default_compute_resource_pool',
}

/**
 * Quota management
 */

export interface WarningMessageMapping {
  ec2VpcElasticIps: string;
  gAndVt: string;
  [key: string]: string;
  natGateways: string;
  p: string;
  standard: string; // Standard Instances (A, C, D, H, I, M, R, T, Z)
  vpcs: string;
}
export interface ClusterCreationResourcesConfig {
  ec2VpcElasticIps: number;
  gAndVt: number;
  [key: string]: number;
  natGateways: number;
  p: number;
  standard: number; // Standard Instances (A, C, D, H, I, M, R, T, Z)
  vpcs: number;
}

export enum InstanceUse {
  Aux = 'aux',
  Compute = 'compute',
  Master = 'master',
}
