import Button from 'hew/Button';
import Icon from 'hew/Icon';
import LogViewer from 'hew/LogViewer/LogViewer';
import React, { MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Message from 'components/Message';
import Page from 'components/Page';
import Section from 'components/Section';
import Spinner from 'components/Spinner';
import { useStore } from 'contexts/Store';
import { useFetchClusters } from 'experimental/notifications/hooks';
import { useFetchSupportMatrix } from 'hooks/useFetch';
import { handlePath, paths, regionalServerAddress } from 'routes/utils';
import { streamingLogsRequest } from 'services/api';
import { regionalVersion } from 'services/apiConfig';
import { FetchArgs, ModelEventInfo } from 'services/regional-bindings';
import * as types from 'types';
import handleError from 'utils/error';
import { processApiError } from 'utils/service';

import css from './LogViewPage.module.scss';

// turns the EventInfo struct into a Log struct
const decoder = <T extends ModelEventInfo>(data: unknown): types.Log => {
  const logData = data as T;
  return {
    id: logData.id,
    message: logData.message,
    time: logData.timestamp as unknown as string,
  };
};

const LogViewPage: React.FC = () => {
  const [canceler] = useState(() => new AbortController());
  const navigate = useNavigate();
  const routeParams = useParams<{ clusterId: string }>();
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);
  const fetchClusters = useFetchClusters(canceler);
  const {
    orgState: { activeClusters, selectedOrg },
    supportMatrix,
  } = useStore();

  const clusterId = routeParams['clusterId'] as string;
  const orgId = selectedOrg?.id as string;
  let regionId = '';
  for (const key in activeClusters) {
    const clusters = activeClusters[key];
    for (const cluster of clusters) {
      if (cluster.id === clusterId) regionId = key;
    }
  }

  useEffect(() => {
    if (!supportMatrix) fetchSupportMatrix();
    if (!activeClusters) fetchClusters();
  }, [activeClusters, fetchClusters, fetchSupportMatrix, supportMatrix]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  const [rateError, setRateError] = useState(false);
  const link = `https://console.cloud.google.com/logs/query;query=resource.type%3D"k8s_pod"%0Aresource.labels."cluster_name"%3D"${clusterId}"`;

  const onClick = (event: MouseEvent): void => {
    handlePath(event, { external: true, path: link, popout: 'tab' });
  };

  // returns regional API endpoint address
  const serverAddress = useCallback(
    (path: string): string => {
      return regionalServerAddress(regionId, regionalVersion + path);
    },
    [regionId],
  );

  // get parameters needed to make API call
  const handleFetch = useCallback((): FetchArgs => {
    return streamingLogsRequest(clusterId, orgId, regionId);
  }, [clusterId, orgId, regionId]);

  const onError = useCallback((e: unknown): void => {
    processApiError('', e).then(function (apiErr) {
      if (apiErr.publicMessage?.includes('RATE_LIMIT_EXCEEDED')) {
        setRateError(true);
      } else {
        handleError(apiErr, { publicSubject: 'Fetching logs failed' });
      }
    });
  }, []);

  if (!selectedOrg || !supportMatrix)
    return <Message title="Waiting for organization information.." />;

  const title: ReactNode = (
    <>
      <Button size="middle" type="text" onClick={() => navigate(paths.clusters())}>
        <div className={css.buttonIcon}>
          <Icon name="arrow-left" size="small" title="back" />
        </div>
      </Button>
      Cluster Failure Logs — {clusterId}
    </>
  );

  return (
    <Page>
      {rateError ? (
        <>
          <Section title={title}>
            <div style={{ textAlign: 'center' }}>
              <Message
                title="We've reached the rate limit for fetching logs from GCP. Go to the Log Explorer to see more."
                type="warning"
              />
              <Button htmlType="button" type="primary" onClick={onClick}>
                GCP Log Explorer
              </Button>
            </div>
          </Section>
        </>
      ) : regionId === '' ? (
        <Spinner center />
      ) : (
        // TODO: LogViewer calls the backend to fetch logs but there are no easy way to
        // retry it without changing LogViewer. But we don't want to do that since it's
        // inside the UI Kit.
        <LogViewer
          decoder={decoder}
          serverAddress={serverAddress}
          title={title}
          onError={onError}
          onFetch={handleFetch}
        />
      )}
    </Page>
  );
};

export default LogViewPage;
