import { Table } from 'antd';
import Select from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import Pivot from 'hew/Pivot';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useStore } from 'contexts/Store';
import { getRootCategoryName } from 'pages/Clusters/cluster-util';
import { InstanceUse } from 'saasTypes';

import css from './InstanceTypeDropdown.module.scss';
import {
  auxCategories,
  computeCategories,
  InstanceTypeInfo,
  InstanceTypeValues,
  KEY_COLUMN_NAME,
  masterCategories,
} from './InstanceTypeDropdown.values';
import { defaultRowClassName } from './Table';

export { auxCategories, computeCategories };

interface TableProps {
  category: InstanceTypeCategory;
  onSelect: (instanceType: string) => void;
}
const InstanceTypeCategoryTable = ({ category, onSelect }: TableProps) => {
  const onTableRow = useCallback(
    (record: InstanceTypeInfo) => {
      return {
        onClick: () => {
          onSelect(`${record[KEY_COLUMN_NAME]}`);
        },
      };
    },
    [onSelect],
  );

  return (
    <Table<InstanceTypeInfo>
      className={css.dropdownTable}
      columns={category.columns}
      dataSource={category.rows}
      pagination={false}
      rowClassName={defaultRowClassName([css.dropdownTableRow], { clickable: true })}
      rowKey="instance"
      scroll={{ x: 'max-content' }}
      size="small"
      onRow={onTableRow}
    />
  );
};

interface DropdownProps {
  buttonLabel?: string;
  id?: string;
  clusterRegion: string;
  onSelect: (instanceType: string) => void;
  use: InstanceUse;
}

const InstanceTypeDropdown: React.FC<DropdownProps> = ({
  clusterRegion,
  buttonLabel,
  onSelect,
  use,
  id,
}: DropdownProps) => {
  const { supportMatrix } = useStore();
  const [tabsData, setTableData] = useState<InstanceTypeCategory[]>();

  const getTabsData = (supportedInstanceTypes: string[]) => {
    interface CategoriesData {
      [categoryKey: string]: InstanceTypeCategory;
    }
    const categoriesData: CategoriesData = {};

    supportedInstanceTypes?.forEach((t) => {
      if (typeof t !== 'string' || t.split('.').length < 2) return;
      const categoryKey = getRootCategoryName(t);
      const instanceTypeName = t;
      const category = categoriesData[categoryKey] ?? {
        columns: [],
        description: '',
        rows: [],
      };
      if (InstanceTypeValues[categoryKey]) {
        category.rows.push(InstanceTypeValues[categoryKey].values[instanceTypeName]);
        category.description = InstanceTypeValues[categoryKey].description;
        category.columns = InstanceTypeValues[categoryKey]?.columns;
      } else {
        category.rows.push({ [KEY_COLUMN_NAME]: instanceTypeName });
        category.columns = [
          {
            dataIndex: KEY_COLUMN_NAME,
            title: KEY_COLUMN_NAME,
          },
        ];
      }
      category.categoryKey = categoryKey;
      categoriesData[categoryKey] = category;
    });
    return Object.values(categoriesData);
  };

  const getTypesToDisplay = (supportedAgentTypes: string[], knownCategories: string[]) => {
    return (
      supportedAgentTypes?.filter((t) => {
        const categoryKey = t.split('.')[0];
        return (
          knownCategories.includes(categoryKey) ||
          // always include unknown categories:
          (!auxCategories.includes(categoryKey) &&
            !computeCategories.includes(categoryKey) &&
            !masterCategories.includes(categoryKey))
        );
      }) ?? []
    );
  };

  useEffect(() => {
    if (!supportMatrix) return;
    let typesToDisplay: string[] = [];
    if (use === InstanceUse.Master) {
      typesToDisplay = supportMatrix.awsSupportMatrix.supportedMasterTypes[clusterRegion];
    }
    if (use === InstanceUse.Aux) {
      typesToDisplay = getTypesToDisplay(
        supportMatrix.awsSupportMatrix.supportedAgentTypes[clusterRegion],
        auxCategories,
      );
    }
    if (use === InstanceUse.Compute) {
      typesToDisplay = getTypesToDisplay(
        supportMatrix.awsSupportMatrix.supportedAgentTypes[clusterRegion],
        computeCategories,
      );
    }
    const tabsData = getTabsData(typesToDisplay);
    if (tabsData) setTableData(tabsData);
  }, [clusterRegion, supportMatrix, use]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownVisibleChange = useCallback((open: boolean) => {
    setDropdownOpen(open);
  }, []);

  const tabItems = useMemo(() => {
    const selectResource = (e: string) => {
      handleDropdownVisibleChange(false);
      onSelect(e);
    };

    return tabsData?.map((category) => {
      return {
        children: (
          <div>
            {category.description && <span>{category.description}</span>}
            <div className={css.tableWrapper}>
              <InstanceTypeCategoryTable category={category} onSelect={selectResource} />
            </div>
          </div>
        ),
        key: category.categoryKey,
        label: category.categoryKey,
      };
    });
  }, [handleDropdownVisibleChange, onSelect, tabsData]);

  return (
    <Select
      dropdownRender={() => <Pivot items={tabItems} type="secondary" />}
      id={id}
      open={dropdownOpen}
      value={buttonLabel}
      onDropdownVisibleChange={handleDropdownVisibleChange}
    />
  );
};

interface InstanceTypeCategory {
  categoryKey: string;
  columns: ColumnsType<InstanceTypeInfo>;
  description: string;
  rows: InstanceTypeInfo[];
}

export default InstanceTypeDropdown;
