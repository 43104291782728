import { useCallback, useEffect, useState } from 'react';

import { useStore } from 'contexts/Store';
import { getBackendProvidersForOrg } from 'services/api';

import { useFetchWithRetry } from './useFetch';

const useBackendProvider = (canceler: AbortController): string => {
  const {
    orgState: { selectedOrg },
  } = useStore();

  const [backendProviderType, setBackendProviderType] = useState<string>('');
  const fetchWithRetry = useFetchWithRetry(canceler);

  const loadBackendProviderType = useCallback(async () => {
    if (!selectedOrg) return;
    try {
      const backendProviders = await fetchWithRetry(
        async () =>
          await getBackendProvidersForOrg({ orgId: selectedOrg.id }, { signal: canceler.signal }),
      );
      if (backendProviders.length === 0) {
        setBackendProviderType('');
        return;
      }
      setBackendProviderType(backendProviders[0].type);
    } catch (e) {
      return;
    }
  }, [canceler.signal, fetchWithRetry, selectedOrg]);

  useEffect(() => {
    loadBackendProviderType();
  }, [loadBackendProviderType]);

  return backendProviderType;
};

export default useBackendProvider;
