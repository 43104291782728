import { Divider } from 'antd';
import Avatar, { Size } from 'hew/Avatar';
import Pivot from 'hew/Pivot';
import React, { useEffect, useMemo, useState } from 'react';

import { telemetryInstance } from 'analytics';
import Message, { MessageType } from 'components/Message';
import Page from 'components/Page';
import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { useFetchClusters } from 'experimental/notifications/hooks';
import usePolling from 'hooks/usePolling';
import { isOrgAdmin } from 'utils/saas';

import BackendProviderForm from './BackendProviderForm';
import css from './Organization.module.scss';
import OrganizationInfoForm from './OrganizationInfoForm';

enum PageTab {
  org = 'Info',
  backendProvider = 'Backend Provider',
}

const Organization: React.FC = () => {
  const {
    orgState: { selectedOrg },
  } = useStore();
  const { roles } = useUser();
  const [currentTab, setTab] = useState<PageTab>(PageTab.org);
  const [canceler] = useState(() => new AbortController());
  const fetchClusters = useFetchClusters(canceler);
  usePolling(fetchClusters);

  useEffect(() => {
    fetchClusters();
  }, [fetchClusters, selectedOrg]);

  useEffect(() => telemetryInstance.page(), []);

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  const tabItems = useMemo(
    () => [
      {
        children: (
          <div className={css.base}>
            <div className={css.orgAvatar}>
              <Avatar noColor size={Size.Large} text={selectedOrg ? selectedOrg.name : ''} />
            </div>
            <Divider />
            <OrganizationInfoForm />
          </div>
        ),
        key: PageTab.org,
        label: 'Info',
      },
      {
        children: (
          <div className={css.base}>
            <BackendProviderForm />
          </div>
        ),
        key: PageTab.backendProvider,
        label: 'Backend Provider',
      },
    ],
    [selectedOrg],
  );
  const isAdmin = useMemo(() => {
    return selectedOrg && isOrgAdmin(roles, selectedOrg?.id);
  }, [roles, selectedOrg]);

  if (!isAdmin) {
    return (
      <div className={css.base}>
        <Message title="You Are Not Authorized To Access This Page" type={MessageType.Warning} />
      </div>
    );
  }

  return (
    <Page title="Organization">
      <Pivot
        activeKey={currentTab}
        defaultActiveKey={PageTab.org}
        items={tabItems}
        onChange={(e: string) => {
          setTab(e as PageTab);
        }}
      />
    </Page>
  );
};

export default Organization;
