import Button from 'hew/Button';
import React from 'react';

import Link from 'components/Link';
import PageMessage from 'components/PageMessage';
import { refreshPage } from 'utils/browser';

interface Props {
  message: string;
  onRefresh?: () => void;
}

const Error: React.FC<Props> = ({ message, onRefresh }: Props) => {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <PageMessage title="Auth failure">
        <p>{message}</p>
        <p>
          If the issue persists, please contact customer support.
          <br />
          Support email:{' '}
          <Link external={true} path={'mailto:ai-support@hpe.com'}>
            ai-support@hpe.com
          </Link>
        </p>
        <Button onClick={onRefresh ?? refreshPage}>Try Again</Button>
      </PageMessage>
    </div>
  );
};

export default Error;
