import { auxCategories, computeCategories } from 'components/InstanceTypeDropdown';
import { getEmptyPoolConfig } from 'components/ResourcePools';
import { awsMldeClusterDefaults, defaultResourcePoolUse } from 'constants/defaults';
import { regionSeriesMappers } from 'pages/Clusters/cluster-util';
import { getEmptyPoolConfig as getEmptyKubernetesPoolConfig } from 'pages/Clusters/GkeCluster/GkeResourcePools';
import {
  IndependentAcceleratorTypesToSupportedCount,
  MachineSeries,
  MachineTypeValues,
} from 'pages/Clusters/GkeCluster/MachineTypeDropdown.values';
import { clusterDefaults as gkeMldeClusterDefaults } from 'pages/Clusters/GkeCluster/NewGkeMldeClusterModal/utils';
import {
  ClusterRole,
  ClusterState,
  failedClusterState,
  OrgRole,
  PoolConfig,
  ResourcePoolUse,
  Roles,
} from 'saasTypes';
import {
  ModelAgentResourceManagerConfig,
  ModelMasterConfig,
  ModelResourcePoolConfig,
} from 'services/regional-bindings';
import { Backend, Eventually, Location, SemanticVersion } from 'types';
import handleError, { DetError, ErrorLevel } from 'utils/error';
import { semVerIsOlder, sortVersions } from 'utils/sort';
import { generateUUID, stringToVersion, versionToString } from 'utils/string';

/** Determine if the user can manage a cluster's users */
export const isClusterAdmin = (userRoles: Roles, orgId: string, clusterId: string): boolean => {
  const allRoles = userRoles[orgId];
  const orgRole = allRoles.role;
  // Assign cluster override role if one is set, otherwise use default cluster role
  const clusterRole = allRoles.clusterRoles[clusterId]
    ? allRoles.clusterRoles[clusterId]
    : allRoles.defaultClusterRole;

  return orgRole === OrgRole.Admin || clusterRole === ClusterRole.Admin;
};

export const getClusterRole = (userRoles: Roles, orgId: string, clusterId: string): string => {
  // Assign cluster override role if one is set, otherwise use default cluster role
  return userRoles[orgId].clusterRoles[clusterId]
    ? userRoles[orgId].clusterRoles[clusterId]
    : userRoles[orgId].defaultClusterRole;
};

/** Determine if the user is an org admin */
export const isOrgAdmin = (userRoles: Roles, orgId: string): boolean => {
  const orgRoles = userRoles[orgId];
  const role = orgRoles.role;
  return role === OrgRole.Admin;
};

/** generate a list of suitable versions higher than the current version */
export const suitableVersions = (available: string[], curVer: string): SemanticVersion[] => {
  return available
    .map((v) => stringToVersion(v))
    .filter((v) => curVer !== versionToString(v) && semVerIsOlder(stringToVersion(curVer), v))
    .sort((a, b) => (semVerIsOlder(a, b) ? 1 : -1));
};

export const getLatestVersion = (versions: string[]): string | undefined => {
  if (versions.length === 0) return undefined;
  const sorted = sortVersions(versions.map((v) => stringToVersion(v)));
  return versionToString(sorted[0]);
};

export const generateMasterConfig = (
  poolConfigs: PoolConfig[],
  resourceManager: ModelAgentResourceManagerConfig,
  validate?: boolean,
): ModelMasterConfig => {
  // CHECK this probably can take in pool names for default aux
  // and compute pools
  const resourcePools = poolConfigs.map((p) => encodePoolConfig(p, validate));

  const error: DetError = new DetError(undefined, {
    publicSubject: 'Invalid master config',
    silent: true,
  });

  if (poolConfigs.length === 0) {
    error.publicMessage = 'At least one pool required';
    throw handleError(error);
  }

  if (!resourceManager.default_aux_resource_pool) {
    throw handleError(undefined, {
      level: ErrorLevel.Error,
      publicSubject: 'Default aux pool required',
      silent: false,
    });
  }
  if (!resourceManager.default_compute_resource_pool) {
    throw handleError(undefined, {
      level: ErrorLevel.Error,
      publicSubject: 'Default compute pool required',
      silent: false,
    });
  }

  return {
    resource_manager: resourceManager,
    resource_pools: resourcePools,
  };
};

export const generateGkeMasterConfig = (
  poolConfigs: PoolConfig[],
  resourceManager: ModelAgentResourceManagerConfig,
  validate?: boolean,
): ModelMasterConfig => {
  const resourcePools = poolConfigs.map((p) => encodeKubernetesPoolConfig(p, validate));
  const error: DetError = new DetError(undefined, {
    publicSubject: 'Invalid master config',
    silent: true,
  });

  if (Object.keys(poolConfigs).length === 0) {
    error.publicMessage = 'At least one pool required';
    throw handleError(error);
  }

  if (
    !resourceManager.default_aux_resource_pool ||
    !resourcePools.some((p) => p.pool_name === resourceManager.default_aux_resource_pool)
  ) {
    error.publicMessage = 'Default aux pool required';
    throw handleError(error);
  }

  if (
    !resourceManager.default_compute_resource_pool ||
    !resourcePools.some((p) => p.pool_name === resourceManager.default_compute_resource_pool)
  ) {
    error.publicMessage = 'Default compute pool required';
    throw handleError(error);
  }

  return {
    resource_manager: resourceManager,
    resource_pools: resourcePools,
  };
};

type ConfigValidationError = {
  key: string;
  reason: string;
};

const validatePoolConfigCommon = (
  pool: Partial<PoolConfig> | Partial<PoolConfig>,
): ConfigValidationError[] => {
  const issues: ConfigValidationError[] = [];
  if (!pool.poolName) {
    issues.push({
      key: 'poolName',
      reason: 'Pool name required',
    });
  }
  return issues;
};

type GkeComputePool = Partial<PoolConfig> &
  Required<Pick<PoolConfig, 'instanceType' | 'acceleratorType' | 'acceleratorCount'>>;

const checkGkeSeriesInfoCompute = (
  pool: GkeComputePool,
  seriesInfo: MachineSeries,
): ConfigValidationError[] => {
  const issues = [];
  if (seriesInfo.isLockedToAccelerator) {
    if (seriesInfo.acceleratorType !== pool.acceleratorType) {
      issues.push({
        key: 'instanceType',
        reason: 'GPU type not supported for instance type',
      });
    }
    if (
      seriesInfo.values[pool.instanceType] === undefined ||
      seriesInfo.values[pool.instanceType].accelerators !== pool.acceleratorCount
    ) {
      issues.push({
        key: 'instanceType',
        reason: 'GPU configuration not valid',
      });
    }
  } else {
    if (!seriesInfo.supportedAccelerators.includes(pool.acceleratorType)) {
      issues.push({
        key: 'acceleratorType',
        reason: 'GPU type not supported by instance type',
      });
    } else if (
      !IndependentAcceleratorTypesToSupportedCount[pool.acceleratorType].includes(
        pool.acceleratorCount,
      )
    ) {
      issues.push({
        key: 'acceleratorCount',
        reason: 'GPU count not supported for gpu type',
      });
    }
  }

  return issues;
};

const validateGkePoolConfig = (pool: Partial<PoolConfig>): ConfigValidationError[] => {
  const issues: ConfigValidationError[] = [];
  if (
    pool.instanceType === undefined ||
    MachineTypeValues[regionSeriesMappers['gcp'](pool.instanceType)] === undefined
  ) {
    issues.push({
      key: 'instanceType',
      reason: 'Valid GKE instance type required',
    });
  } else if (pool.primaryUse === ResourcePoolUse.Compute) {
    if (pool.acceleratorType === undefined || pool.acceleratorCount === undefined) {
      issues.push({
        key: 'instanceType',
        reason: 'Valid GPU configuration required',
      });
    } else {
      checkGkeSeriesInfoCompute(
        pool as GkeComputePool,
        MachineTypeValues[regionSeriesMappers['gcp'](pool.instanceType)],
      ).forEach((issue) => issues.push(issue));
    }
  }

  return issues;
};

/**
 * translate keys for standard ts access.
 * @param config
 */
export const decodePoolConfig = (p: ModelResourcePoolConfig): PoolConfig => {
  const getUseByInstanceType = (instanceType: string): ResourcePoolUse => {
    if (computeCategories.includes(instanceType.split('.')[0].toLowerCase())) {
      return ResourcePoolUse.Compute;
    } else if (auxCategories.includes(instanceType.split('.')[0].toLowerCase())) {
      return ResourcePoolUse.Aux;
    } else {
      return defaultResourcePoolUse;
    }
  };

  return {
    acceleratorCount: p.provider.instance_type.gpu_num,
    acceleratorType: p.provider.instance_type.gpu_type,
    cpuSlotsAllowed: p.provider.cpu_slots_allowed,
    instanceType: p.provider.instance_type.machine_type,
    key: generateUUID(),
    maxInstances: p.provider.max_instances,
    poolName: p.pool_name,
    primaryUse:
      p.provider.type === Backend.EC2 || p.provider.type === Backend.EKS
        ? getUseByInstanceType(p.provider.instance_type.machine_type)
        : p.provider.instance_type.gpu_type
        ? ResourcePoolUse.Compute
        : ResourcePoolUse.Aux,
  };
};

/**
 * add in the defaults and translate key names.
 * @param config
 */
export const encodePoolConfig = (
  pool: Partial<PoolConfig>,
  validate = true,
): ModelResourcePoolConfig => {
  if (validate && validatePoolConfigCommon(pool).length) {
    throw handleError(undefined, {
      // TODO pass on the issues
      level: ErrorLevel.Error,
      publicSubject: 'Pool name required',
      silent: false,
    });
  }

  const p = {
    ...getEmptyPoolConfig(1),
    ...pool,
  };
  return {
    max_aux_containers_per_agent: pool.primaryUse === ResourcePoolUse.Aux ? 100 : 0,
    pool_name: p.poolName,
    provider: {
      cpu_slots_allowed: p.cpuSlotsAllowed,
      instance_type: {
        gpu_num: p.acceleratorCount,
        gpu_type: p.acceleratorType,
        machine_type: p.instanceType,
      },
      max_idle_agent_period: p.maxIdleAgentPeriod,
      max_instances: p.maxInstances,
      min_instances: p.minInstances,
      type: awsMldeClusterDefaults.RP_PROVIDER_TYPE,
    },
  };
};

const encodeKubernetesPoolConfig = (
  pool: Partial<PoolConfig>,
  validate = true,
): ModelResourcePoolConfig => {
  if (validate) {
    const issues = [...validatePoolConfigCommon(pool), ...validateGkePoolConfig(pool)];
    if (issues.length) {
      throw issues.first().reason;
    }
  }

  const p = {
    ...getEmptyKubernetesPoolConfig(0),
    ...pool,
  };

  return {
    max_aux_containers_per_agent: pool.primaryUse === ResourcePoolUse.Aux ? 100 : 0,
    pool_name: p.poolName,
    provider: {
      cpu_slots_allowed: p.cpuSlotsAllowed,
      instance_type: {
        gpu_num: p.acceleratorCount,
        gpu_type: p.acceleratorType,
        machine_type: p.instanceType,
      },
      max_instances: p.maxInstances,
      type: gkeMldeClusterDefaults.RP_PROVIDER_TYPE,
    },
  };
};

/* used temporarily to update our error management */
export const ignoreExceptions = (fn: () => Eventually<void>): (() => Eventually<void>) => {
  return () => {
    try {
      fn();
    } catch (e) {
      handleError(e);
      // ignore
    }
  };
};

export const disableClusterLinkByState = (state: ClusterState): boolean => {
  return state !== ClusterState.Running || failedClusterState.has(state as ClusterState);
};

export const getBackendProviderLocation = (providerName: Backend): Location => {
  return providerName === Backend.GKE ? Location.GCP : Location.AWS;
};
