import { Modal } from 'hew/Modal';
import isEqual from 'lodash.isequal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ResourcePools from 'components/ResourcePools';
import Spinner from 'components/Spinner';
import useBackendProvider from 'hooks/useBackendProvider';
import { useFetchWithRetry } from 'hooks/useFetch';
import { ClusterDetails } from 'saasTypes';
import { getCluster, reconfigureCluster } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import { ModelMasterConfig } from 'services/regional-bindings';
import { Backend } from 'types';
import handleError, { ErrorLevel } from 'utils/error';
import { generateGkeMasterConfig, generateMasterConfig } from 'utils/saas';

import { GkeResourcePools } from './GkeCluster/GkeResourcePools';
import css from './UpdateResourcePoolsModal.module.scss';

interface Props {
  onClose?: () => void;
  cluster: GlobalApi.ModelClusterInfo;
  orgId: string;
}

export const _UpdateResourcePoolsModal: React.FC<Props> = ({ orgId, cluster, onClose }) => {
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const [clusterDetails, setClusterDetails] = useState<ClusterDetails>();
  const backendProvider = useBackendProvider(canceler);

  const [masterConfig, setMasterConfig] = useState<ModelMasterConfig>({
    resource_manager: { default_aux_resource_pool: '', default_compute_resource_pool: '' },
    resource_pools: [],
  });

  const reconfigureClusterRequest = useCallback(async () => {
    try {
      if (!masterConfig) return;
      await fetchWithRetry(
        async () =>
          await reconfigureCluster(
            {
              clusterId: cluster.id,
              config: {
                masterConfig: masterConfig,
              },
              orgId,
              regionId: cluster.location,
            },
            { signal: canceler.signal },
          ),
      );
    } catch (error) {
      throw handleError(error, {
        level: ErrorLevel.Error,
        publicSubject: 'Failed to update',
        silent: false,
      });
    }
  }, [canceler.signal, cluster.id, cluster.location, fetchWithRetry, masterConfig, orgId]);

  const handleSubmit = useCallback(async () => {
    await reconfigureClusterRequest();
    onClose?.();
  }, [reconfigureClusterRequest, onClose]);

  const clusterHasUpdates = useMemo(
    () =>
      !!masterConfig &&
      (!clusterDetails?.masterConfig ||
        !isEqual(
          backendProvider === Backend.GKE
            ? generateGkeMasterConfig(
                clusterDetails.masterConfig.resource_pools,
                clusterDetails.masterConfig.resource_manager,
                false,
              )
            : generateMasterConfig(
                clusterDetails.masterConfig.resource_pools,
                clusterDetails.masterConfig.resource_manager,
                false,
              ),
          masterConfig,
        )),
    [backendProvider, clusterDetails?.masterConfig, masterConfig],
  );

  const fetchClusterDetails = useCallback(async () => {
    try {
      const clusterDetailsRes = await fetchWithRetry(
        async () =>
          await getCluster(
            {
              clusterId: cluster.id,
              orgId,
              regionId: cluster.location,
            },
            { signal: canceler.signal },
          ),
      );
      setClusterDetails(clusterDetailsRes);
    } catch (e) {
      handleError(e, {
        publicSubject: 'Failed to fetch cluster details',
      });
    }
  }, [canceler.signal, cluster.id, cluster.location, fetchWithRetry, orgId]);

  useEffect(() => {
    fetchClusterDetails();
  }, [cluster.id, orgId, fetchClusterDetails]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  return (
    <Modal
      cancel={true}
      cancelText="Cancel"
      size="medium"
      submit={{
        disabled: !clusterHasUpdates,
        handleError,
        handler: handleSubmit,
        text: 'Update Resource Pools',
      }}
      title={`Update Resource Pools for ${cluster?.name}`}
      onClose={onClose}>
      {!clusterDetails ? (
        <Spinner tip="Loading cluster details" />
      ) : (
        <div className={css.modalContent}>
          {backendProvider === Backend.GKE ? (
            <GkeResourcePools cluster={clusterDetails} updateMasterConfig={setMasterConfig} />
          ) : (
            <ResourcePools cluster={clusterDetails} updateMasterConfig={setMasterConfig} />
          )}
        </div>
      )}
    </Modal>
  );
};
