import { Input } from 'antd';
import Button from 'hew/Button';
import Form from 'hew/Form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { analyticsEvents, telemetryInstance } from 'analytics';
import { OrgName } from 'components/OrgPicker/OrgFormItems';
import { useStore } from 'contexts/Store';
import { useFetchOrgs, useFetchWithRetry } from 'hooks/useFetch';
import { updateOrgName } from 'services/api';
import { notification } from 'utils/dialogApi';
import handleError, { ErrorLevel } from 'utils/error';

import css from './Organization.module.scss';

interface OrganizationValues {
  id: string;
  name: string;
}

const OrganizationInfoForm: React.FC = () => {
  const [canceler] = useState(() => new AbortController());
  const [organizationForm] = Form.useForm<OrganizationValues>();
  const newOrgName = Form.useWatch('name', organizationForm);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fetchWithRetry = useFetchWithRetry(canceler);

  const fetchOrgs = useFetchOrgs(canceler);
  const {
    orgState: { orgs, selectedOrg },
  } = useStore();

  useEffect(() => {
    if (!selectedOrg || !organizationForm) return;
    organizationForm.setFieldsValue({ id: selectedOrg.id, name: selectedOrg.name });
  }, [organizationForm, selectedOrg]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  const onOrganizationSubmit = useCallback(async () => {
    if (selectedOrg) {
      setIsSubmitting(true);
      try {
        await fetchWithRetry(
          async () => await updateOrgName({ name: newOrgName, orgId: selectedOrg?.id }),
        );
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        handleError(error, {
          level: ErrorLevel.Error,
          publicSubject: 'Failed to update org',
          silent: false,
        });
        return;
      }
      await fetchOrgs();

      notification.info({
        message: 'Organization Updated',
      });
      telemetryInstance.track(analyticsEvents.orgNameUpdate, {
        from: selectedOrg.name,
        orgId: selectedOrg.id,
        to: newOrgName,
      });
    }
  }, [fetchOrgs, newOrgName, selectedOrg, fetchWithRetry]);

  const organizationSaveEnabled = useMemo(
    () => !isSubmitting && !!newOrgName && newOrgName !== selectedOrg?.name,
    [isSubmitting, newOrgName, selectedOrg?.name],
  );

  return (
    <>
      <Form
        form={organizationForm}
        labelCol={{ span: 24 }}
        layout="vertical"
        wrapperCol={{ span: 24 }}
        onFinish={onOrganizationSubmit}>
        <Form.Item label="Organization ID">
          <div className={css.entry}>
            <Form.Item name="id" noStyle>
              <Input disabled={true} />
            </Form.Item>
          </div>
        </Form.Item>
        <OrgName
          currentOrgs={orgs.filter((org) => org.name !== selectedOrg?.name).map((org) => org.name)}
        />
        <Form.Item className={css.submit}>
          <Button
            disabled={!organizationSaveEnabled}
            htmlType="submit"
            loading={isSubmitting}
            type="primary">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default OrganizationInfoForm;
