import { Divider } from 'antd';
import Form from 'hew/Form';
import Input from 'hew/Input';
import { Modal } from 'hew/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import RegionPicker from 'components/RegionPicker';
import { awsMldmMsgExplainCharges } from 'constants/messages';
import { useStore } from 'contexts/Store';
import { useMakeClusterCreateCall } from 'hooks/useFetch';
import { validateClusterRequestObject } from 'pages/Clusters/cluster-util';
import { ModelCreateClusterRequest } from 'services/regional-bindings';
import handleError from 'utils/error';
import { generateMasterConfig } from 'utils/saas';

import BasicConfigSelect from './BasicConfig';
import css from './NewAwsMldmClusterModal.module.scss';
import { basicStandardClusterRequest, initialState } from './NewAwsMldmClusterModal.settings';

interface Props {
  onFinish: () => Promise<void>;
  isModalOpen?: boolean;
  onClose: () => void;
}

export interface NetworkingParams {
  ipRange: string;
  private0: string;
  private1: string;
  public: string;
}

export const _NewAwsMldmClusterModal: React.FC<Props> = ({ onFinish, onClose }) => {
  /**
    This modal offers a Basic configuration option for creating an MLDM Cluster
   */
  const [form] = Form.useForm();
  const { supportMatrix } = useStore();

  const [canceler] = useState(() => new AbortController());
  const makeClusterCreateCall = useMakeClusterCreateCall(canceler);

  const basicConfigSelection = initialState.basicConfigSelection;

  const [clusterName, setClusterName] = useState<string>('');
  const [clusterRegion, setClusterRegion] = useState<string>('');
  const masterConfig = generateMasterConfig(initialState.poolConfigs, initialState.resourceManager);
  const [networkingParams, setNetworkingParams] = useState<Partial<NetworkingParams>>({});
  const updateNetworkingParams = useCallback(
    (p: Partial<NetworkingParams>) => setNetworkingParams((prev) => ({ ...prev, ...p })),
    [setNetworkingParams],
  );

  const handleSubmit = useCallback(() => {
    // handleOk uses async/await so that if `generateMasterConfig` throws a data validation error,
    // it can return a rejected promise, which prevents modal from closing

    const makeBasicClusterRequestObject = (
      basicClusterRequest: Required<
        Omit<ModelCreateClusterRequest, 'detVersion' | 'ipRange' | 'subnetRanges' | 'appVersions'>
      >,
    ) => {
      return {
        ...basicClusterRequest,
        //TODO: retrieve this from the support matrix
        appVersions: {
          mldm: '2.8.3',
        },

        ipRange: supportMatrix?.awsSupportMatrix.defaultIpRange ?? '',

        name: clusterName,
        subnetRanges: supportMatrix?.awsSupportMatrix.defaultSubnetRanges,
      };
    };
    // Create the request object with all required creation parameters
    const clusterRequest = makeBasicClusterRequestObject(basicStandardClusterRequest);
    validateClusterRequestObject(clusterRequest);
    return makeClusterCreateCall(clusterRegion, clusterRequest, onFinish);
  }, [
    clusterName,
    clusterRegion,
    makeClusterCreateCall,
    onFinish,
    supportMatrix?.awsSupportMatrix.defaultIpRange,
    supportMatrix?.awsSupportMatrix.defaultSubnetRanges,
  ]);

  useEffect(() => {
    if (supportMatrix) {
      updateNetworkingParams({
        ipRange: supportMatrix.awsSupportMatrix.defaultIpRange,
        ...supportMatrix.awsSupportMatrix.defaultSubnetRanges,
      });
    }
  }, [supportMatrix, updateNetworkingParams]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  const modalContent = useMemo(() => {
    /* TODO: Once we move to getting MLDM versions from the matrix, add logic to display loading spinner
    i.e. if (!supportedPachydermVersions.length) return <Spinner tip="Loading..." />; */
    return (
      <div className={css.modalContent}>
        <Form form={form} labelCol={{ span: 24 }}>
          <Form.Item label="Region" name="region">
            <RegionPicker
              curRegion={clusterRegion}
              id="region"
              regionFilter={(region) => region.startsWith('aws')}
              onChange={setClusterRegion}
            />
          </Form.Item>
          <Form.Item
            label="Cluster Name"
            name="clusterName"
            rules={[{ message: 'Cluster name required', required: true }]}>
            <Input
              value={clusterName}
              onChange={(e) => {
                setClusterName(e.target.value);
              }}
            />
          </Form.Item>
          <Divider />
        </Form>
        <BasicConfigSelect
          selected={basicConfigSelection}
          onSelect={() => {
            /**/
          }}
        />
        <Divider />
        <div> {awsMldmMsgExplainCharges} </div>
      </div>
    );
  }, [basicConfigSelection, clusterName, clusterRegion, form]);

  const submitDisabled = useMemo(
    () =>
      !networkingParams ||
      !clusterName ||
      !masterConfig ||
      masterConfig?.resource_pools.length === 0,
    [clusterName, masterConfig, networkingParams],
  );

  return (
    <Modal
      cancel={true}
      cancelText="Cancel"
      size="medium"
      submit={{
        disabled: submitDisabled,
        handleError,
        handler: handleSubmit,
        text: 'Create Cluster',
      }}
      title="New Cluster"
      onClose={onClose}>
      {modalContent}
    </Modal>
  );
};
