import { useCallback } from 'react';

import { StoreAction, useStore, useStoreDispatch } from 'contexts/Store';
import useBackendProvider from 'hooks/useBackendProvider';
import { useFetchWithRetry } from 'hooks/useFetch';
import { ClusterState } from 'saasTypes';
import { ModelClusterInfo } from 'services/global-bindings';
import { Backend } from 'types';
import handleError from 'utils/error';
import { getBackendProviderLocation } from 'utils/saas';

import { fetchSelectRegionalClusters } from './api';
import { openNotification } from './popup';
import { notifStorage, SubscriptionType } from './storage';

interface Options {
  skipIfNoSubscribers?: boolean;
  rethrowError?: boolean;
}
/**
 *
 * @param canceler
 * @param options
 * @returns
 */
export const useFetchClusters = (
  canceler: AbortController,
  options: Options = { rethrowError: false, skipIfNoSubscribers: false },
): (() => Promise<ModelClusterInfo[] | void>) => {
  const {
    orgState: { selectedOrg },
    supportMatrix,
  } = useStore();
  const fetchWithRetry = useFetchWithRetry(canceler);

  const storeDispatch = useStoreDispatch();

  const backendProviderName = useBackendProvider(canceler);

  const doFetch = useCallback(async () => {
    if (!supportMatrix?.supportedLocations || !selectedOrg?.id) return;
    if (
      options?.skipIfNoSubscribers &&
      (!notifStorage.isStorageSetup() || !notifStorage.hasActiveSubscriptions)
    )
      return;
    try {
      const backendLocation = getBackendProviderLocation(backendProviderName as Backend);
      const clusters = await fetchWithRetry(
        async () =>
          await fetchSelectRegionalClusters(
            {
              orgId: selectedOrg.id,
              regions: backendProviderName
                ? supportMatrix.supportedLocations.filter((l) => l.startsWith(backendLocation))
                : [],
            },
            { signal: canceler.signal },
          ),
      );
      storeDispatch({ type: StoreAction.SetActiveClusters, value: clusters });

      Object.entries(clusters).forEach(([regionId, regionClusters]) => {
        triggerNotifications(regionId, regionClusters);
      });
      return Object.values(clusters).flat();
    } catch (e) {
      if (options?.rethrowError) {
        throw e;
      } else {
        handleError(e, {
          publicSubject: 'Failed to fetch clusters',
          silent: true,
        });
      }
    }
  }, [
    canceler.signal,
    backendProviderName,
    fetchWithRetry,
    options?.rethrowError,
    options?.skipIfNoSubscribers,
    selectedOrg?.id,
    storeDispatch,
    supportMatrix?.supportedLocations,
  ]);

  return doFetch;
};

/**
 * denotes that we should stop listening for this ask.
 * either the condition has been met now or in the past or it has failed.
 */
const settleConditions: Record<SubscriptionType, (c: ModelClusterInfo) => boolean> = {
  [SubscriptionType.ClusterCreated]: (c) => c.state !== ClusterState.Creating,
  [SubscriptionType.ClusterDeleted]: (c) => c.state !== ClusterState.Deleting,
  [SubscriptionType.ClusterPaused]: (c) => c.state !== ClusterState.Pausing,
  [SubscriptionType.ClusterResumed]: (c) => c.state !== ClusterState.Resuming,
  [SubscriptionType.ClusterUpgraded]: (c) => c.state !== ClusterState.Upgrading,
  [SubscriptionType.ClusterReconfigured]: (c) => c.state !== ClusterState.Reconfiguring,
  [SubscriptionType.ClusterReprovisioned]: (c) => c.state !== ClusterState.Reprovisioning,
};

const triggerNotifications = (regionId: string, clusters: ModelClusterInfo[]): void => {
  if (!notifStorage.isStorageSetup()) return;
  const subs = notifStorage.listClusterSubs()[regionId] || [];
  clusters
    .filter((c) => c.id in subs)
    .forEach((c) => {
      const subType = subs[c.id];
      if (settleConditions[subType](c)) {
        notifStorage.removeClusterSub(c);
        openNotification(c, { id: c.id, type: subType });
      }
    });
};

export default {};
