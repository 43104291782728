import { awsMldmClusterDefaults } from 'constants/defaults';
import { BasicConfigOption, PoolConfig, ResourcePoolUse } from 'saasTypes';
import { ModelCreateClusterRequest } from 'services/regional-bindings';
import { generateUUID } from 'utils/string';

export const basicStandardClusterRequest: Required<
  Omit<ModelCreateClusterRequest, 'detVersion' | 'ipRange' | 'subnetRanges' | 'appVersions'>
> = {
  masterConfig: {
    resource_manager: {
      default_aux_resource_pool: awsMldmClusterDefaults.CPU_POOL_NAME,
      default_compute_resource_pool: awsMldmClusterDefaults.GPU_POOL_NAME,
    },
    resource_pools: [
      {
        max_aux_containers_per_agent: 100,
        pool_name: awsMldmClusterDefaults.CPU_POOL_NAME,
        provider: {
          cpu_slots_allowed: awsMldmClusterDefaults.CPU_SLOTS_ALLOWED,
          instance_type: {
            machine_type: awsMldmClusterDefaults.AUX_INSTANCE_TYPE,
          },
          max_instances: awsMldmClusterDefaults.MAX_AUX_INSTANCES,
          type: awsMldmClusterDefaults.AWS,
        },
      },
      {
        max_aux_containers_per_agent: 0,
        pool_name: awsMldmClusterDefaults.GPU_POOL_NAME,
        provider: {
          cpu_slots_allowed: awsMldmClusterDefaults.CPU_SLOTS_ALLOWED,
          instance_type: {
            machine_type: awsMldmClusterDefaults.COMPUTE_INSTANCE_TYPE,
          },
          max_instances: awsMldmClusterDefaults.MAX_COMPUTE_INSTANCES,
          type: awsMldmClusterDefaults.AWS,
        },
      },
    ],
  },
  masterInstanceType: awsMldmClusterDefaults.MASTER_INSTANCE_TYPE_STANDARD,
  name: '',
};
export const defaultPoolConfigs: PoolConfig[] = [
  {
    cpuSlotsAllowed: true,
    instanceType: awsMldmClusterDefaults.AUX_INSTANCE_TYPE,
    key: generateUUID(),
    maxInstances: awsMldmClusterDefaults.MAX_AUX_INSTANCES,
    poolName: awsMldmClusterDefaults.CPU_POOL_NAME,
    primaryUse: ResourcePoolUse.Aux,
  },
  {
    cpuSlotsAllowed: false,
    instanceType: awsMldmClusterDefaults.COMPUTE_INSTANCE_TYPE,
    key: generateUUID(),
    maxInstances: awsMldmClusterDefaults.MAX_COMPUTE_INSTANCES,
    poolName: awsMldmClusterDefaults.GPU_POOL_NAME,
    primaryUse: ResourcePoolUse.Compute,
  },
];

export const initialState = {
  basicConfigSelection: BasicConfigOption.Standard,
  defaultAccountResources: {
    // Set to infinity so if the precheck fails, no warning message will be displayed
    ec2VpcElasticIps: Number.POSITIVE_INFINITY,
    gAndVt: Number.POSITIVE_INFINITY,
    natGateways: Number.POSITIVE_INFINITY,
    p: Number.POSITIVE_INFINITY,
    standard: Number.POSITIVE_INFINITY,
    vpcs: Number.POSITIVE_INFINITY,
  },
  isAdvanced: false,
  overallConfig: {
    detVersion: '',
    masterInstanceType: awsMldmClusterDefaults.MASTER_INSTANCE_TYPE_STANDARD,
  },
  poolConfigs: defaultPoolConfigs,
  requiredAccountResources: {
    // # of instances are calculated based on user selection, below are static amounts per cluster creation
    ec2VpcElasticIps: awsMldmClusterDefaults.REQUIRED_EC2VPCELASTICIPS,
    gAndVt: 0,
    natGateways: awsMldmClusterDefaults.REQUIRED_NATGATEWAYS,
    p: 0,
    standard: 0,
    vpcs: awsMldmClusterDefaults.REQUIRED_VPCS,
  },
  resourceManager: {
    default_aux_resource_pool: awsMldmClusterDefaults.CPU_POOL_NAME,
    default_compute_resource_pool: awsMldmClusterDefaults.GPU_POOL_NAME,
  },
};
