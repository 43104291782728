import { parse, validate } from '@aws-sdk/util-arn-parser';
import { Divider } from 'antd';
import Button from 'hew/Button';
import Form from 'hew/Form';
import Select, { Option } from 'hew/Select';
import isEqual from 'lodash.isequal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import awsLogoDark from 'assets/images/aws-logo-on-dark.svg';
import awsLogo from 'assets/images/aws-logo.svg';
import gcpLogo from 'assets/images/gcp-logo.svg';
import Message, { MessageType } from 'components/Message';
import {
  AwsAgentInstanceProfile,
  AwsCrossAccountRoleItem,
  AwsMasterInstanceProfile,
  GcpProjectId,
} from 'components/OrgPicker/OrgFormItems';
import useUI from 'components/UIManager';
import { useStore } from 'contexts/Store';
import { RegionalClusters } from 'experimental/notifications/api';
import useConfig, { Config } from 'hooks/useConfig';
import { useFetchSupportMatrix, useFetchWithRetry } from 'hooks/useFetch';
import { getBackendProvidersForOrg, updateBackendProvider } from 'services/api';
import {
  ModelBackendProvider,
  ModelFullBackendProviderEntry,
  ModelGetSupportMatrixResponse,
} from 'services/global-bindings';
import { AWSBackendProviderFields, Backend, GKEBackendProviderFields } from 'types';
import { notification } from 'utils/dialogApi';
import handleError, { ErrorLevel, wrapPublicMessage } from 'utils/error';

import css from './BackendProviderForm.module.scss';

interface BackendProviderValues {
  agentInstanceProfile: string;
  type: string;
  masterInstanceProfile: string;
  projectId: string;
  xAcctRole: string;
}

interface BaseBackendProviderProps {
  currentBackendProviderInfo: ModelBackendProvider;
}

interface AwsProps extends BaseBackendProviderProps {
  activeClusters: RegionalClusters | undefined;
  backendProviderValues: BackendProviderValues;
  clearConnectionInfo: () => void;
  config?: Config;
  supportMatrix: ModelGetSupportMatrixResponse | undefined;
}

const AwsFormItems: React.FC<AwsProps> = ({
  activeClusters,
  backendProviderValues,
  config,
  currentBackendProviderInfo,
  clearConnectionInfo,
}) => {
  const { ui } = useUI();

  const checkArn = useCallback(
    (arn: string, field: string) => {
      if (!validate(arn)) {
        return Promise.reject(new Error('ARN syntax is invalid'));
      }
      if (activeClusters !== undefined) {
        const currentAccountArn = parse(currentBackendProviderInfo.connectionInfo[field]);
        const updatedAccountArn = parse(arn);
        if (currentAccountArn.accountId !== updatedAccountArn.accountId) {
          return Promise.reject(
            new Error(
              'Cannot update account ID while clusters are present. Please delete clusters and try again.',
            ),
          );
        }
      }
      return Promise.resolve();
    },
    [activeClusters, currentBackendProviderInfo.connectionInfo],
  );

  const selectItems = useMemo(() => {
    return (
      <>
        <Option key={Backend.EC2} value={Backend.EC2}>
          {Backend.EC2.toUpperCase()}
        </Option>
        {config?.mldmEnabled && (
          <Option key={Backend.EKS} value={Backend.EKS}>
            {Backend.EKS.toUpperCase()}
          </Option>
        )}
      </>
    );
  }, [config]);
  if (!currentBackendProviderInfo || !config) return null;

  return (
    <>
      <div className={css.logoWrapper}>
        {ui.darkLight === 'light' ? (
          <img alt="AWS Logo" src={awsLogo} />
        ) : (
          <img alt="AWS Logo" src={awsLogoDark} />
        )}
      </div>
      <Divider />
      <div style={{ display: !config.mldmEnabled ? 'none' : undefined }}>
        <Form.Item
          extra={<div>Choose between EC2 or EKS for your infrastructure.</div>}
          label="Backend Type"
          name="type"
          required={true}>
          <Select
            disabled={activeClusters !== undefined || !config.mldmEnabled}
            onChange={clearConnectionInfo}>
            {selectItems}
          </Select>
        </Form.Item>
      </div>
      <AwsCrossAccountRoleItem
        rules={[
          {
            validator: (_, value) => checkArn(value, AWSBackendProviderFields.XAcctRole),
          },
        ]}
      />
      {backendProviderValues.type === Backend.EC2 && (
        <>
          <AwsMasterInstanceProfile
            rules={[
              {
                validator: (_, value) =>
                  checkArn(value, AWSBackendProviderFields.MasterInstanceProfile),
              },
            ]}
          />
          <AwsAgentInstanceProfile
            rules={[
              {
                validator: (_, value) =>
                  checkArn(value, AWSBackendProviderFields.AgentInstanceProfile),
              },
            ]}
          />
        </>
      )}
    </>
  );
};

const GcpFormItems: React.FC<BaseBackendProviderProps> = ({ currentBackendProviderInfo }) => {
  if (!currentBackendProviderInfo) return null;
  return (
    <>
      <div className={css.logoWrapper}>
        <img alt={'GCP logo'} src={gcpLogo} />
      </div>
      <Divider />
      {/* TODO: This is a bit of a workaround to maintain the backend type that gets populated initially. Think through a better way of handling this scenario */}
      <div style={{ display: 'none' }}>
        <Form.Item label="Backend Type" name="type">
          <Select disabled={true} />
        </Form.Item>
      </div>
      <GcpProjectId />
    </>
  );
};

const BackendProviderForm: React.FC = () => {
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);
  const [backendProviderForm] = Form.useForm<BackendProviderValues>();
  const providerValues = Form.useWatch<BackendProviderValues>([], backendProviderForm);
  const [backendProviderLoading, setBackendProviderLoading] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [currentBackendProviderInfo, setCurrentBackendProviderInfo] =
    useState<ModelFullBackendProviderEntry>();
  const config = useConfig(canceler);

  const {
    orgState: { activeClusters, selectedOrg },
    supportMatrix,
  } = useStore();

  const clearConnectionInfo = useCallback(() => {
    backendProviderForm.setFieldsValue({
      agentInstanceProfile: '',
      masterInstanceProfile: '',
      projectId: '',
      xAcctRole: '',
    });
  }, [backendProviderForm]);

  const fetchBackendProviders = useCallback(async () => {
    if (!selectedOrg) return;
    let backendProviders = [] as ModelFullBackendProviderEntry[];
    try {
      backendProviders = await fetchWithRetry(
        async () =>
          await getBackendProvidersForOrg({ orgId: selectedOrg.id }, { signal: canceler.signal }),
      );
    } catch (error) {
      handleError(error, {
        publicSubject: "Failed to fetch organization's cloud provider",
      });
      return;
    }
    if (backendProviders.length === 0) {
      setCurrentBackendProviderInfo(undefined);
      return;
    }
    setCurrentBackendProviderInfo(backendProviders[0]);
  }, [canceler.signal, fetchWithRetry, selectedOrg]);

  const onBackendProviderSubmit = useCallback(async () => {
    if (!currentBackendProviderInfo || !selectedOrg) return;
    let inputs = {} as BackendProviderValues;
    const backendProvider = {} as ModelBackendProvider;
    try {
      inputs = await backendProviderForm.validateFields();
    } catch (exception) {
      return;
    }

    switch (inputs.type) {
      case Backend.EC2:
        backendProvider.connectionInfo = {
          agentInstanceProfile: inputs.agentInstanceProfile,
          masterInstanceProfile: inputs.masterInstanceProfile,
          xAcctRole: inputs.xAcctRole,
        };
        break;
      case Backend.EKS:
        backendProvider.connectionInfo = {
          xAcctRole: inputs.xAcctRole,
        };
        break;
      case Backend.GKE:
        backendProvider.connectionInfo = {
          projectId: inputs.projectId,
        };
        break;
    }
    backendProvider.type = inputs.type;
    setBackendProviderLoading(true);
    try {
      const response = await fetchWithRetry(
        async () =>
          await updateBackendProvider({
            backendProvider: backendProvider,
            backendProviderId: currentBackendProviderInfo.id,
            orgId: selectedOrg.id,
          }),
      );
      if (response.warnings && Object.keys(response.warnings).length > 0) {
        let warningMessage = 'The following permissions are not present in updated roles:\n\n';
        for (const item in response.warnings) {
          warningMessage += `Role ${item}:\n`;
          const values = response.warnings[item];
          warningMessage += values.join('\n');
        }
        notification.warning({
          description: <div className={css.notification}>{warningMessage}</div>,
          message: 'Cloud Provider Updated With Warnings',
        });
      } else {
        notification.info({ message: 'Cloud Provider Updated' });
      }
      await fetchBackendProviders();
    } catch (error) {
      handleError(error, {
        leaveOpen: true,
        level: ErrorLevel.Error,
        publicMessage: wrapPublicMessage(error, 'Failed to update cloud provider'),
        silent: false,
      });
    }
    setBackendProviderLoading(false);
  }, [
    backendProviderForm,
    currentBackendProviderInfo,
    fetchBackendProviders,
    fetchWithRetry,
    selectedOrg,
  ]);

  useEffect(() => {
    if (!currentBackendProviderInfo || !backendProviderForm) return;
    backendProviderForm.setFieldsValue({
      agentInstanceProfile:
        currentBackendProviderInfo?.connectionInfo[AWSBackendProviderFields.AgentInstanceProfile],
      masterInstanceProfile:
        currentBackendProviderInfo?.connectionInfo[AWSBackendProviderFields.MasterInstanceProfile],
      projectId: currentBackendProviderInfo?.connectionInfo[GKEBackendProviderFields.ProjectId],
      type: currentBackendProviderInfo.type,
      xAcctRole: currentBackendProviderInfo?.connectionInfo[AWSBackendProviderFields.XAcctRole],
    });
  }, [backendProviderForm, currentBackendProviderInfo]);

  useEffect(() => {
    fetchBackendProviders();
  }, [fetchBackendProviders]);

  useEffect(() => {
    if (!currentBackendProviderInfo || Object.keys(providerValues).length === 0) return;
    const flattenedInfo = {
      type: currentBackendProviderInfo.type,
      ...currentBackendProviderInfo.connectionInfo,
    };
    backendProviderForm.validateFields({ validateOnly: true }).then(
      () => setIsSubmitEnabled(!backendProviderLoading && !isEqual(providerValues, flattenedInfo)),
      () => setIsSubmitEnabled(false),
    );
  }, [backendProviderForm, backendProviderLoading, currentBackendProviderInfo, providerValues]);

  useEffect(() => {
    if (!supportMatrix) fetchSupportMatrix();
  }, [fetchSupportMatrix, supportMatrix]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  return (
    <>
      {currentBackendProviderInfo ? (
        <Form
          form={backendProviderForm}
          initialValues={{
            agentInstanceProfile:
              currentBackendProviderInfo?.connectionInfo[
                AWSBackendProviderFields.AgentInstanceProfile
              ],
            masterInstanceProfile:
              currentBackendProviderInfo?.connectionInfo[
                AWSBackendProviderFields.MasterInstanceProfile
              ],
            projectId:
              currentBackendProviderInfo?.connectionInfo[GKEBackendProviderFields.ProjectId],
            type: currentBackendProviderInfo.type,
            xAcctRole:
              currentBackendProviderInfo?.connectionInfo[AWSBackendProviderFields.XAcctRole],
          }}
          labelCol={{ span: 24 }}
          layout="vertical"
          wrapperCol={{ span: 24 }}
          onFinish={onBackendProviderSubmit}>
          {currentBackendProviderInfo.type === Backend.GKE ? (
            <GcpFormItems currentBackendProviderInfo={currentBackendProviderInfo} />
          ) : (
            <AwsFormItems
              activeClusters={activeClusters}
              backendProviderValues={providerValues}
              clearConnectionInfo={clearConnectionInfo}
              config={config}
              currentBackendProviderInfo={currentBackendProviderInfo}
              supportMatrix={supportMatrix}
            />
          )}
          <div style={{ float: 'right' }}>
            <Form.Item>
              <Button
                disabled={!isSubmitEnabled}
                htmlType="submit"
                loading={backendProviderLoading}
                type="primary">
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      ) : (
        <Message title="No Configured Backend Provider" type={MessageType.Warning} />
      )}
    </>
  );
};

export default BackendProviderForm;
