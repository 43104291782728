import { DarkLight } from 'hew/internal/types';
import { useEffect } from 'react';

import useUI from 'components/UIManager';

const lightThemeSrc =
  'https://h50007.www5.hpe.com/hfws/us/en/hpe/latest.r/root?contentType=js&switchToCHF=true&hide_country_selector=true&hide_cart=true&hide_sign_in=true&hide_cube_menu=true&hide_sitemap=true&expand_view=true';
const darkThemeSrc = lightThemeSrc + '&color_scheme=dark';

const useSyncHeaderFooterTheme = (): void => {
  const {
    ui: { darkLight },
  } = useUI();

  useEffect(() => {
    const script = document.createElement('script');

    if (darkLight === DarkLight.Dark) {
      script.setAttribute('src', darkThemeSrc);
    } else {
      script.setAttribute('src', lightThemeSrc);
    }
    document.head.appendChild(script);

    return () => script.remove();
  }, [darkLight]);
};

export default useSyncHeaderFooterTheme;
