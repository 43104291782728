import Icon from 'hew/Icon';

function AccessLevelInfoIcon(title: string, description: string) {
  return (
    <span>
      <span style={{ marginRight: '3px' }}>{title}</span>
      <Icon name="info" showTooltip size="tiny" title={description} />
    </span>
  );
}

export function OrgLevelAccessIcon(): JSX.Element {
  return AccessLevelInfoIcon('Organization Role', "The user's access level to the organization");
}

export function ClusterLevelAccessIcon(): JSX.Element {
  return AccessLevelInfoIcon(
    'Default Cluster Role',
    'The cluster role for this user for every cluster - overridable, but org admins are always cluster admins',
  );
}
