import { Card, Form, FormInstance, Input, InputNumber, Radio, RadioChangeEvent } from 'antd';
import Button from 'hew/Button';
import Select, { Option } from 'hew/Select';
import Tooltip from 'hew/Tooltip';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import Link from 'components/Link';
import { awsMldeClusterDefaults, defaultResourcePoolUse } from 'constants/defaults';
import { useStore } from 'contexts/Store';
import { defaultPoolConfigs } from 'pages/Clusters/NewAwsMldeClusterModal/NewAwsMldeClusterModal.settings';
import {
  ClusterDetails,
  DefaultResourcePool,
  InstanceUse,
  PoolConfig,
  ResourcePoolUse,
} from 'saasTypes';
import { ModelAgentResourceManagerConfig, ModelMasterConfig } from 'services/regional-bindings';
import { generateMasterConfig } from 'utils/saas';
import { generateUUID } from 'utils/string';

import InstanceTypeDropdown from './InstanceTypeDropdown';
import css from './ResourcePools.module.scss';

export const getEmptyPoolConfig = (num: number): PoolConfig => {
  return {
    cpuSlotsAllowed: awsMldeClusterDefaults.CPU_SLOTS_ALLOWED,
    instanceType: awsMldeClusterDefaults.AUX_INSTANCE_TYPE,
    // add a unique id for each pool so we/react can track changes.
    key: generateUUID(),
    maxIdleAgentPeriod: awsMldeClusterDefaults.MAX_IDLE_AGENT_PERIOD,
    maxInstances: awsMldeClusterDefaults.MAX_AUX_INSTANCES,
    minInstances: awsMldeClusterDefaults.MIN_AUX_INSTANCES,
    poolName: `resource-pool-${num}`,
    primaryUse: defaultResourcePoolUse,
  };
};

interface PoolConfigCardProps {
  clusterRegion: string;

  onRemove: (key: string) => void;
  onUpdate: (key: string, form: FormInstance) => void;
  onUpdateResourceManager: (keys: DefaultResourcePool[], value?: string) => void;
  poolConfig: PoolConfig;
  resourceManager: ModelAgentResourceManagerConfig;
}
const PoolConfigCard: React.FC<PoolConfigCardProps> = ({
  clusterRegion,
  poolConfig,

  onRemove,
  onUpdate,
  onUpdateResourceManager,
  resourceManager,
}: PoolConfigCardProps) => {
  const [form] = Form.useForm<PoolConfig>();

  useEffect(() => {
    form.validateFields();
  }, [poolConfig, form]);

  const onPrimaryUseChange = useCallback(
    (e: RadioChangeEvent) => {
      const instanceTypeValue =
        e.target.value === ResourcePoolUse.Compute
          ? awsMldeClusterDefaults.COMPUTE_INSTANCE_TYPE
          : awsMldeClusterDefaults.AUX_INSTANCE_TYPE;
      form.setFieldsValue({ instanceType: instanceTypeValue });
      onUpdate(poolConfig.key, form);
    },
    [form, onUpdate, poolConfig],
  );

  const onNoMinInstance = useCallback(
    (value: number | null) => {
      if (value == null) {
        form.setFieldsValue({ minInstances: 0 });
        onUpdate(poolConfig.key, form);
      }
    },
    [form, onUpdate, poolConfig],
  );
  const onNoMaxInstance = useCallback(
    (value: number | null) => {
      if (value == null) {
        form.setFieldsValue({ maxInstances: poolConfig.minInstances });
        onUpdate(poolConfig.key, form);
      }
    },
    [form, onUpdate, poolConfig],
  );

  return (
    <Card
      className={css.card}
      extra={
        <Button
          onClick={() => {
            if (
              resourceManager.default_aux_resource_pool === poolConfig.poolName &&
              resourceManager.default_compute_resource_pool === poolConfig.poolName
            ) {
              onUpdateResourceManager([DefaultResourcePool.aux, DefaultResourcePool.compute]);
            } else if (resourceManager.default_aux_resource_pool === poolConfig.poolName) {
              onUpdateResourceManager([DefaultResourcePool.aux]);
            } else if (resourceManager.default_compute_resource_pool === poolConfig.poolName) {
              onUpdateResourceManager([DefaultResourcePool.compute]);
            }
            onRemove(poolConfig.key);
          }}>
          Remove
        </Button>
      }
      key={poolConfig.key}
      title={poolConfig.poolName}>
      <Form<PoolConfig>
        form={form}
        initialValues={poolConfig}
        labelCol={{ span: 24 }}
        onValuesChange={() => onUpdate(poolConfig.key, form)}>
        <Form.Item
          htmlFor={'poolName ' + poolConfig.poolName}
          label="Pool Name"
          name="poolName"
          rules={[{ message: 'Pool name required', required: true }]}>
          <Input
            id={'poolName ' + poolConfig.poolName}
            onChange={(e) => {
              const keys = [];
              const value = e.target.value;
              if (resourceManager.default_aux_resource_pool === poolConfig.poolName) {
                keys.push(DefaultResourcePool.aux);
              }
              if (resourceManager.default_compute_resource_pool === poolConfig.poolName) {
                keys.push(DefaultResourcePool.compute);
              }
              if (keys.length) onUpdateResourceManager(keys, value);
            }}
          />
        </Form.Item>
        <Form.Item
          htmlFor={'instanceCategory ' + poolConfig.poolName}
          label="Instance Category"
          name="primaryUse">
          <Radio.Group
            id={'instanceCategory ' + poolConfig.poolName}
            options={[
              {
                label: (
                  <Tooltip content="Select to see instance types for tasks that do not require dedicated compute resources.">
                    Auxiliary Tasks
                  </Tooltip>
                ),
                value: ResourcePoolUse.Aux,
              },
              {
                label: (
                  <Tooltip content="Select to see instance types that support compute tasks, i.e. GPUs or dedicated CPUs.">
                    Compute
                  </Tooltip>
                ),
                value: ResourcePoolUse.Compute,
              },
            ]}
            optionType="button"
            onChange={onPrimaryUseChange}
          />
        </Form.Item>
        <Form.Item
          className={css.formItemWithLink}
          htmlFor={'instanceType ' + poolConfig.poolName}
          label={
            <>
              <span>Instance Type</span>
              <Link
                external={true}
                path={`https://aws.amazon.com/ec2/instance-types/${
                  poolConfig.primaryUse === ResourcePoolUse.Aux
                    ? '#Compute_Optimized'
                    : '#Accelerated_Computing'
                }`}
                popout={true}>
                List of Instance Types
              </Link>
            </>
          }
          name="instanceType">
          <InstanceTypeDropdown
            buttonLabel={poolConfig.instanceType}
            clusterRegion={clusterRegion}
            id={'instanceType ' + poolConfig.poolName}
            use={
              poolConfig.primaryUse === ResourcePoolUse.Aux ? InstanceUse.Aux : InstanceUse.Compute
            }
            onSelect={(instanceType: string) => {
              form.setFieldsValue({ instanceType });
              onUpdate(poolConfig.key, form);
            }}
          />
        </Form.Item>
        <div className={css.formItemHorizontal}>
          <Form.Item
            htmlFor={'minInstances ' + poolConfig.poolName}
            label="Min Instances"
            name="minInstances"
            rules={[
              {
                validator: (_, value) => {
                  if (value) {
                    if (value > poolConfig.maxInstances) {
                      return Promise.reject(
                        'Min number of instances cannot be larger than max number of instances',
                      );
                    }
                    return Promise.resolve();
                  }
                },
              },
            ]}>
            <InputNumber
              id={'minInstances ' + poolConfig.poolName}
              max={poolConfig.maxInstances}
              min={0}
              onChange={onNoMinInstance}
            />
          </Form.Item>
          <Form.Item
            htmlFor={'maxInstances ' + poolConfig.poolName}
            label="Max Instances"
            name="maxInstances"
            rules={[
              {
                validator: (_, value) => {
                  if (value && poolConfig.minInstances !== undefined) {
                    if (value < poolConfig.minInstances) {
                      return Promise.reject(
                        'Max number of instances cannot be smaller than min number of instances',
                      );
                    }
                    return Promise.resolve();
                  }
                },
              },
            ]}>
            <InputNumber<number>
              id={'maxInstances ' + poolConfig.poolName}
              min={poolConfig.minInstances || 1}
              onChange={onNoMaxInstance}
            />
          </Form.Item>
          <Form.Item
            htmlFor={'maxIdleAgentPeriod ' + poolConfig.poolName}
            label="Max Idle Agent Period"
            name="maxIdleAgentPeriod"
            rules={[
              {
                validator: (_, value) => {
                  if (value) {
                    const pattern = /^[0-9]+(?:\.[0-9]+)?(?:s|m|h)$/; // pattern for duration strings like "1.5s", "1.5m", or "1.5h"
                    if (!pattern.test(value)) {
                      return Promise.reject(
                        new Error(
                          'Invalid duration format. Must be time unit (s, m, h) or decimal (e.g. 10m for 10 minutes)',
                        ),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
            tooltip="Maximum amount of time an agent can be idle before shutting down. For example, for a 10 minute duration it would be 10m. Valid time units are “s”, “m”, “h”">
            <Input id={'maxIdleAgentPeriod ' + poolConfig.poolName} style={{ width: '90px' }} />
          </Form.Item>
        </div>
      </Form>
    </Card>
  );
};

interface Props {
  updateMasterConfig: Dispatch<SetStateAction<ModelMasterConfig>>;
  cluster?: ClusterDetails;
}

const defaultState = {
  pools: defaultPoolConfigs,
  resourceManager: {
    default_aux_resource_pool: awsMldeClusterDefaults.CPU_POOL_NAME,
    default_compute_resource_pool: awsMldeClusterDefaults.GPU_POOL_NAME,
  },
};

const ResourcePools: React.FC<Props> = ({ cluster, updateMasterConfig }) => {
  const [form] = Form.useForm();

  const [pools, setPools] = useState<PoolConfig[]>(
    () => cluster?.masterConfig.resource_pools ?? defaultState.pools,
  );
  const [resourceManager, setResourceManager] = useState<ModelAgentResourceManagerConfig>(
    () => cluster?.masterConfig.resource_manager ?? defaultState.resourceManager,
  );

  useEffect(() => {
    form.validateFields();
  }, [pools, form]);

  const { supportMatrix } = useStore();

  const location = useMemo(
    () => supportMatrix?.supportedLocations.find((region) => region.startsWith('aws')) ?? '',
    [supportMatrix?.supportedLocations],
  );

  useEffect(() => {
    try {
      updateMasterConfig(generateMasterConfig(pools, resourceManager));
    } catch (e) {
      updateMasterConfig({
        resource_manager: { default_aux_resource_pool: '', default_compute_resource_pool: '' },
        resource_pools: [],
      });
    }
  }, [pools, resourceManager, updateMasterConfig]);

  const onRemovePoolConfig = useCallback(
    (key: string) => {
      if (!pools) return;
      const poolConfigsCopy = [...pools];
      const idx = poolConfigsCopy.findIndex((a) => a.key === key);
      poolConfigsCopy.splice(idx, 1);
      setPools(poolConfigsCopy);
    },
    [pools],
  );

  const onUpdatePoolConfig = useCallback(
    (key: string, poolForm: FormInstance) => {
      if (!pools) return;
      const poolConfigsCopy = [...pools];
      const idx = poolConfigsCopy.findIndex((a) => a.key === key);
      poolConfigsCopy[idx] = { ...poolConfigsCopy[idx], ...poolForm.getFieldsValue() };
      setPools(poolConfigsCopy);
    },
    [pools],
  );

  const onAddPoolConfig = useCallback(() => {
    setPools((poolConfigs) => {
      if (!poolConfigs) return poolConfigs;
      return [getEmptyPoolConfig(Object.keys(poolConfigs).length + 1), ...poolConfigs];
    });
  }, []);

  const onUpdateResourceManager = useCallback(
    (keys: DefaultResourcePool[], value?: string) => {
      if (!value) return;
      const rm = { ...resourceManager };
      keys.forEach((key: DefaultResourcePool) => {
        if (key === DefaultResourcePool.aux) {
          rm.default_aux_resource_pool = value;
        }
        if (key === DefaultResourcePool.compute) {
          rm.default_compute_resource_pool = value;
        }
      });
      setResourceManager(rm as ModelAgentResourceManagerConfig);
    },
    [resourceManager],
  );

  return (
    <>
      <Form form={form} labelCol={{ span: 24 }}>
        <h2 className={css.sectionHeader}>
          Resource Pools
          <Button onClick={onAddPoolConfig}>Add</Button>
        </h2>
        <Form.Item
          initialValue={resourceManager.default_aux_resource_pool}
          label="Default Aux Pool"
          name="defaultAuxPool"
          required
          rules={[
            {
              message: 'Valid Default Aux Pool Required',
              required: true,
              validator: (_, val) => {
                if (pools === undefined) return;
                if (!pools.find((p) => p.poolName === val)) {
                  return Promise.reject('Default Aux Pool must be found in resource pools');
                }
                return Promise.resolve();
              },
            },
          ]}
          tooltip="The default resource pool to use for tasks that do not need dedicated compute resources: auxiliary or system tasks.">
          <Select
            id="defaultAuxPool"
            onChange={(value) => {
              onUpdateResourceManager([DefaultResourcePool.aux], String(value));
            }}>
            {pools?.map((p) => (
              <Option key={p.key} value={p.poolName}>
                {p.poolName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          initialValue={resourceManager.default_compute_resource_pool}
          label="Default Compute Pool"
          name="defaultComputePool"
          required
          rules={[
            {
              message: 'Valid Default Compute Pool Required',
              required: true,
              validator: (_, val) => {
                if (pools === undefined) return;
                if (!pools.find((p) => p.poolName === val)) {
                  return Promise.reject('Default Compute Pool must be found in resource pools');
                }
                return Promise.resolve();
              },
            },
          ]}
          tooltip=" The default resource pool to use for tasks that require compute resources, e.g. GPUs or dedicated CPUs.">
          <Select
            id="defaultComputePool"
            onChange={(value) => {
              onUpdateResourceManager([DefaultResourcePool.compute], String(value));
            }}>
            {pools?.map((p) => (
              <Option key={p.key} value={p.poolName}>
                {p.poolName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <div className={css.pools}>
        {pools?.map((poolConfig: PoolConfig) => {
          return (
            <PoolConfigCard
              clusterRegion={location}
              key={poolConfig.key}
              poolConfig={poolConfig}
              resourceManager={resourceManager}
              onRemove={onRemovePoolConfig}
              onUpdate={onUpdatePoolConfig}
              onUpdateResourceManager={onUpdateResourceManager}
            />
          );
        })}
      </div>
    </>
  );
};

export default ResourcePools;
