import { useEffect, useState } from 'react';

import { useStore } from 'contexts/Store';
import { Application } from 'types';

import { useFetchSupportMatrix } from './useFetch';

export interface Config {
  mldeEnabled: boolean;
  mldmEnabled: boolean;
}

const useConfig = (canceler: AbortController): Config | undefined => {
  const [config, setConfig] = useState<Config | undefined>(undefined);
  const { supportMatrix } = useStore();
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);

  useEffect(() => {
    if (!supportMatrix) {
      fetchSupportMatrix();
      return;
    }
    const config: Config = {
      mldeEnabled: supportMatrix?.supportedAppVersions
        ? Application.MLDE in supportMatrix.supportedAppVersions
        : false,
      mldmEnabled: supportMatrix?.supportedAppVersions
        ? Application.MLDM in supportMatrix.supportedAppVersions
        : false,
    };
    setConfig(config);
  }, [fetchSupportMatrix, supportMatrix]);

  return config;
};

export default useConfig;
