import { Card } from 'antd';
import Button from 'hew/Button';
import { useModal } from 'hew/Modal';
import React, { useCallback, useEffect, useState } from 'react';

import OnboardingInvitesComponent from 'components/OnboardingInvites';
import NewOrg from 'components/OrgPicker/NewOrgModal';
import PageMessage from 'components/PageMessage';
import Spinner from 'components/Spinner';
import TrialStart from 'components/Trial/TrialStartInfo';
import useUI from 'components/UIManager';
import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { useFetchOrgs, useFetchSupportMatrix, useFetchWithRetry } from 'hooks/useFetch';
import Error from 'pages/Error';
import { fetchUsersInvites, startTrial } from 'services/api';
import { ModelUserInvite } from 'services/global-bindings';
import handleError from 'utils/error';
import { routeToReactUrl } from 'utils/routes';

import css from './Onboarding.module.scss';

enum Step {
  Init = 0,
  PendingInvites = 1,
  TrialSignUp = 2,
  OrgCreation = 3,
}

const Onboarding: React.FC = () => {
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const [error, setError] = useState<string>();
  const [currentStep, setCurrentStep] = useState(Step.Init);
  const NewOrgModal = useModal(NewOrg);
  const doFetchOrgs = useFetchOrgs(canceler);
  const { supportMatrix } = useStore();
  const user = useUser();
  const { actions: uiActions } = useUI();

  const [invites, setInvites] = useState<ModelUserInvite[]>([]);

  const fetchSupportMatrix = useFetchSupportMatrix(canceler);

  useEffect(() => {
    uiActions.hideSpinner();
    uiActions.hideChrome();
    return uiActions.showChrome;
  }, [uiActions]);

  useEffect(() => {
    const invitesCheck = async () => {
      if (user) {
        try {
          const { invites } = await fetchUsersInvites(user.userId);
          if (invites?.length) setInvites(invites);
        } catch (e) {
          setError('Failed to fetch org invites. Please try logging in again later.');
        }
      }
    };
    invitesCheck();
  }, [user]);

  useEffect(() => {
    if (invites.length) {
      setCurrentStep(Step.PendingInvites);
    } else if (
      currentStep !== Step.OrgCreation &&
      user.licensing.enabled &&
      !user.licensing.userLicensingStatus.active &&
      !user.superAdmin
    ) {
      setCurrentStep(Step.TrialSignUp);
    } else {
      setCurrentStep(Step.OrgCreation);
    }
  }, [
    currentStep,
    invites,
    user.licensing.enabled,
    user.licensing.userLicensingStatus.active,
    user.superAdmin,
  ]);

  useEffect(() => {
    if (currentStep === Step.OrgCreation) NewOrgModal.open();
  }, [currentStep, NewOrgModal]);

  const onCloseOrgModal = useCallback(async () => {
    // verify that the org has been created.
    const orgs = await doFetchOrgs();
    if (!orgs) return; // request aborted or failed;
    const isOrgCreated = orgs.length === 0;
    if (!isOrgCreated) return;
    // TODO handle unhappy path
  }, [doFetchOrgs]);

  const trialSubmit = useCallback(async () => {
    if (!user) return;
    try {
      await fetchWithRetry(
        async () => await startTrial({ userId: user.userId }, { signal: canceler.signal }),
      );
      await user.refetchLicenseStatus();
      setCurrentStep(Step.OrgCreation);
    } catch (e) {
      setError('Failed to start free trial');
      handleError(e, {
        publicSubject: 'Failed to start trial',
      });
    }
  }, [canceler, fetchWithRetry, user]);

  useEffect(() => {
    if (!supportMatrix) fetchSupportMatrix();
  }, [supportMatrix, fetchSupportMatrix]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  if (error) {
    return <Error message={error} />;
  }

  const trialAgreement = (
    <div className={css.trialAgreement}>
      <div className={css.cardWrapper}>
        <Card className={css.card}>
          <TrialStart />
          <div className={css.startNow}>
            <div className={css.button}>
              <Button onClick={() => routeToReactUrl('/logout')}>No Thanks</Button>
            </div>
            <div className={css.button}>
              <Button type="primary" onClick={trialSubmit}>
                Start Now
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );

  return (
    <div className={css.base}>
      {currentStep === Step.PendingInvites && user ? (
        <OnboardingInvitesComponent invites={invites} userId={user.userId} />
      ) : currentStep === Step.TrialSignUp ? (
        <div className={css.trialAgreementWrapper}>{trialAgreement}</div>
      ) : currentStep === Step.OrgCreation && supportMatrix ? (
        supportMatrix.newOrgs ? (
          <PageMessage title="Onboarding">
            <NewOrgModal.Component onClose={onCloseOrgModal} />
          </PageMessage>
        ) : (
          <div className={css.newOrgsDisabledContainer}>
            <PageMessage title="Onboarding">
              <p>Please contact your administrator to be added to the organization</p>
            </PageMessage>
          </div>
        )
      ) : (
        <PageMessage title="">
          <Spinner />
        </PageMessage>
      )}
    </div>
  );
};

export default Onboarding;
