export const awsMldeMsgExplainCharges = `
Initiating a cluster will result in costs being accrued within your linked AWS cloud account. These expenses are distinct from any charges levied by HPE. Clusters can take 5-10 minutes to spin up.
`;

export const awsMldmMsgExplainCharges = `
Initiating a cluster will result in costs being accrued within your linked AWS cloud account. These expenses are distinct from any charges levied by HPE. Clusters can take 15-20 minutes to spin up.
`;

export const gcpMsgExplainCharges = `
Initiating a cluster will result in costs being accrued within your linked GCP cloud account. These expenses are distinct from any charges levied by HPE. Clusters can take 15-20 minutes to spin up.
`;
