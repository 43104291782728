import { red } from '@ant-design/colors';
import Dropdown, { MenuDivider, MenuItem } from 'hew/Dropdown';
import { useModal } from 'hew/Modal';
import useConfirm from 'hew/useConfirm';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { actionsButton } from 'components/Table';
import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { useFetchWithRetry } from 'hooks/useFetch';
import {
  ClusterRole,
  ClusterState,
  failedClusterState,
  stableClusterState,
  transitionalClusterState,
  upgradeableStates,
} from 'saasTypes';
import { pauseCluster, resumeCluster } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import { MenuOptionWithOnClick } from 'types';
import { copyToClipboard } from 'utils/dom';
import handleError, { DetError } from 'utils/error';
import { getClusterRole, isClusterAdmin, suitableVersions } from 'utils/saas';

import css from './ClusterActionDropdown.module.scss';
import { _ClusterActionHistoryModal } from './ClusterActionHistoryModal';
import { _ClusterDeleteModal } from './ClusterDeleteModal';
import { _ClusterUsersModal } from './ClusterUsersModal';
import { _RenameClusterModal } from './RenameClusterModal';
import { _ReprovisionMasterModal } from './ReprovisionMasterModal';
import { _UpdateResourcePoolsModal } from './UpdateResourcePoolsModal';
import { _UpgradeClusterVersionModal } from './UpgradeClusterVersionModal';

export enum ClusterAction {
  Delete = 'Delete',
  ViewAccess = 'View Access',
  ManageAccess = 'Manage Access',
  Pause = 'Pause',
  Upgrade = 'Upgrade',
  Rename = 'Rename Cluster',
  Resume = 'Resume',
  UpdateMaster = 'Reprovision Master',
  UpdatePools = 'Update Resource Pools',
  CopyClusterLink = 'Copy Cluster Link',
  ClusterActionHistory = 'View Action History',
}

interface Props {
  children?: ReactNode;
  cluster: GlobalApi.ModelClusterInfo;
  loadClusters: () => void;
  supportedDetVersions: string[];
  orgId: string;
}

const ClusterActionDropdown: React.FC<Props> = ({
  children,
  cluster,
  loadClusters,
  supportedDetVersions,
  orgId,
}) => {
  const { orgState } = useStore();
  const { licensing, roles } = useUser();
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const confirm = useConfirm();
  const RenameClusterModal = useModal(_RenameClusterModal);
  const ReprovisionMasterModal = useModal(_ReprovisionMasterModal);
  const UpdateResourcePoolsModal = useModal(_UpdateResourcePoolsModal);
  const UpgradeClusterVersionModal = useModal(_UpgradeClusterVersionModal);
  const ClusterUsersModal = useModal(_ClusterUsersModal);
  const ClusterActionHistoryModal = useModal(_ClusterActionHistoryModal);
  const ClusterDeleteModal = useModal(_ClusterDeleteModal);

  const isDropdownDisabled = useMemo(() => {
    const needTrial = licensing?.enabled && !orgState?.selectedOrg?.licensingStatus?.active;
    if (needTrial) return true;
    return (
      !isClusterAdmin(roles, orgId, cluster.id) &&
      getClusterRole(roles, orgId, cluster.id) === ClusterRole.None
    );
  }, [roles, licensing.enabled, orgState?.selectedOrg?.licensingStatus?.active, orgId, cluster.id]);

  const hasAdminPrivileges = useMemo(() => {
    return isClusterAdmin(roles, orgId, cluster.id);
  }, [roles, orgId, cluster.id]);

  const actions: Record<ClusterAction, MenuOptionWithOnClick> = useMemo(
    () => ({
      [ClusterAction.CopyClusterLink]: {
        key: ClusterAction.CopyClusterLink,
        label: ClusterAction.CopyClusterLink,
        onClick: () => copyToClipboard(`https://${cluster.endpoint}/`),
      },
      [ClusterAction.ClusterActionHistory]: {
        key: ClusterAction.ClusterActionHistory,
        label: ClusterAction.ClusterActionHistory,
        onClick: ClusterActionHistoryModal.open,
      },
      [ClusterAction.ManageAccess]: {
        key: ClusterAction.ManageAccess,
        label: ClusterAction.ManageAccess,
        onClick: ClusterUsersModal.open,
      },
      [ClusterAction.ViewAccess]: {
        key: ClusterAction.ViewAccess,
        label: ClusterAction.ViewAccess,
        onClick: ClusterUsersModal.open,
      },
      [ClusterAction.Upgrade]: {
        disabled:
          !upgradeableStates.has(cluster.state as ClusterState) ||
          suitableVersions(supportedDetVersions, cluster.detVersion || '').length < 1 ||
          !hasAdminPrivileges,
        key: ClusterAction.Upgrade,
        label: ClusterAction.Upgrade,
        onClick: UpgradeClusterVersionModal.open,
      },
      [ClusterAction.UpdateMaster]: {
        disabled: !hasAdminPrivileges,
        key: ClusterAction.UpdateMaster,
        label: ClusterAction.UpdateMaster,
        onClick: ReprovisionMasterModal.open,
      },
      [ClusterAction.Rename]: {
        key: ClusterAction.Rename,
        label: ClusterAction.Rename,
        onClick: RenameClusterModal.open,
      },

      [ClusterAction.UpdatePools]: {
        disabled: !hasAdminPrivileges,
        key: ClusterAction.UpdatePools,
        label: ClusterAction.UpdatePools,
        onClick: UpdateResourcePoolsModal.open,
      },
      [ClusterAction.Delete]: {
        disabled: !hasAdminPrivileges,
        key: ClusterAction.Delete,
        label: <div style={{ color: red[5] }}>{ClusterAction.Delete}</div>,
        onClick: ClusterDeleteModal.open,
      },
      [ClusterAction.Pause]: {
        disabled: !hasAdminPrivileges,
        key: ClusterAction.Pause,
        label: ClusterAction.Pause,
        onClick: () => {
          confirm({
            content: `Are you sure you want to pause cluster ${cluster.name}?`,
            onConfirm: async () => {
              await fetchWithRetry(async () => {
                await pauseCluster(
                  { clusterId: cluster.id, orgId, regionId: cluster.location },
                  { signal: canceler.signal },
                );
              });
              loadClusters();
            },
            onError: (e) => {
              handleError(e, {
                publicSubject: 'Failed to pause cluster',
              });
            },
            title: 'Pause Cluster',
          });
        },
      },
      [ClusterAction.Resume]: {
        disabled: !hasAdminPrivileges,
        key: ClusterAction.Resume,
        label: ClusterAction.Resume,
        onClick: () => {
          confirm({
            content: `Are you sure you want to resume cluster ${cluster.name}?`,
            onConfirm: async () => {
              await fetchWithRetry(
                async () =>
                  await resumeCluster(
                    { clusterId: cluster.id, orgId, regionId: cluster.location },
                    { signal: canceler.signal },
                  ),
              );
              loadClusters();
            },
            onError: (e) => {
              handleError(e, {
                publicSubject: 'Failed to resume cluster',
              });
            },
            title: 'Resume Cluster',
          });
        },
      },
    }),

    [
      canceler.signal,
      ClusterActionHistoryModal.open,
      ClusterUsersModal.open,
      cluster.detVersion,
      cluster.endpoint,
      cluster.id,
      cluster.location,
      cluster.name,
      cluster.state,
      fetchWithRetry,
      confirm,
      hasAdminPrivileges,
      loadClusters,
      orgId,
      RenameClusterModal.open,
      ReprovisionMasterModal.open,
      supportedDetVersions,
      UpgradeClusterVersionModal.open,
      UpdateResourcePoolsModal.open,
      ClusterDeleteModal.open,
    ],
  );

  const dropdownMenuItems: MenuItem[] = useMemo(() => {
    const divider: MenuDivider = { type: 'divider' };
    const items: MenuItem[] = [
      actions[ClusterAction.CopyClusterLink],
      divider,
      actions[ClusterAction.ClusterActionHistory],
    ];

    if (isClusterAdmin(roles, orgId, cluster.id)) {
      items.push(actions[ClusterAction.ManageAccess]);
    } else {
      items.push(actions[ClusterAction.ViewAccess]);
    }
    // check for valid cluster items.
    if (
      transitionalClusterState.has(cluster.state as ClusterState) ||
      cluster.state === ClusterState.Deleted
    )
      return items;
    if (
      !stableClusterState.has(cluster.state as ClusterState) &&
      !failedClusterState.has(cluster.state as ClusterState)
    ) {
      // sanity check. all states should be either in stable, failed, or transitional.
      throw new DetError(undefined, {
        publicMessage: 'Cluster is in an invalid state',
        silent: true,
      });
    }

    items.push(divider, actions[ClusterAction.Rename]);
    if (cluster.detVersion) {
      items.push(
        actions[ClusterAction.Upgrade],
        actions[ClusterAction.UpdateMaster],
        actions[ClusterAction.UpdatePools],
      );

      if (cluster.state === ClusterState.Running || cluster.state === ClusterState.PauseFailed) {
        items.push(actions[ClusterAction.Pause]);
      } else if (cluster.state === ClusterState.Paused) {
        items.push(actions[ClusterAction.Resume]);
      }
    }
    items.push(divider, actions[ClusterAction.Delete]);

    return items;
  }, [actions, roles, orgId, cluster.id, cluster.state, cluster.detVersion]);

  // signal cancellation on unmount
  useEffect(() => () => canceler.abort(), [canceler]);

  return (
    <>
      <div className={css.focus}>
        <Dropdown disabled={isDropdownDisabled} isContextMenu={false} menu={dropdownMenuItems}>
          {children ?? actionsButton}
        </Dropdown>
      </div>
      <ClusterActionHistoryModal.Component
        cluster={cluster}
        orgId={orgId}
        regionId={cluster.location}
        onClose={loadClusters}
      />
      <UpdateResourcePoolsModal.Component cluster={cluster} orgId={orgId} onClose={loadClusters} />
      <ReprovisionMasterModal.Component cluster={cluster} orgId={orgId} onClose={loadClusters} />
      <RenameClusterModal.Component cluster={cluster} orgId={orgId} onClose={loadClusters} />
      <ClusterUsersModal.Component clusterId={cluster.id} orgId={orgId} />
      <UpgradeClusterVersionModal.Component
        cluster={cluster}
        orgId={orgId}
        supportedDetVersions={supportedDetVersions}
        onClose={loadClusters}
      />
      <ClusterDeleteModal.Component
        cluster={cluster}
        loadClusters={loadClusters}
        orgId={orgId}
        onClose={loadClusters}
      />
    </>
  );
};

export default ClusterActionDropdown;
