import React, { useEffect } from 'react';

import Spinner from 'components/Spinner';
import useUI from 'components/UIManager';
import { globalStorage } from 'globalStorage';
import { externalLoginAddress, routeAll } from 'routes/utils';

const SignIn: React.FC = () => {
  const { actions: uiActions } = useUI();

  useEffect(() => {
    globalStorage.currentAuthAttempt += 1;
    const authUrl = `${externalLoginAddress()}`;
    routeAll(authUrl);
  }, []);

  useEffect(() => {
    uiActions.hideChrome();
    return uiActions.showChrome;
  }, [uiActions]);

  return (
    <Spinner
      center
      style={{ height: '100vh', width: '100%' }}
      tip={
        'Welcome to HPE Machine Learning Development Environment Software. Your access is being verified...'
      }>
      <div />
    </Spinner>
  );
};

export default SignIn;
