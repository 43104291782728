import { ColumnType } from 'antd/es/table';
import { Modal } from 'hew/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ResponsiveTable from 'components/ResponsiveTable';
import Spinner from 'components/Spinner';
import { useFetchWithRetry } from 'hooks/useFetch';
import { getClusterActions, getOrgMembers } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import * as RegionalApi from 'services/regional-bindings';
import handleError, { wrapPublicMessage } from 'utils/error';
import { alphaNumericSorter, dateTimeStringSorter } from 'utils/sort';

interface Props {
  onClose?: () => void;
  cluster: GlobalApi.ModelClusterInfo;
  orgId: string;
  regionId: string;
}

export const _ClusterActionHistoryModal: React.FC<Props> = ({
  orgId,
  cluster,
  regionId,
  onClose,
}) => {
  const [actions, setActions] = useState<RegionalApi.ModelActionInfo[] | null>(null);
  const [users, setUsers] = useState<GlobalApi.ModelOrgUser[]>([]);
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);

  const fetchData = useCallback(async () => {
    try {
      const actions = await fetchWithRetry(
        async () => await getClusterActions({ cluster, orgId, regionId }),
      );
      setActions(actions);
    } catch (e) {
      handleError(e, {
        publicSubject: 'Failed to fetch history',
      });
    }
  }, [cluster, orgId, regionId, fetchWithRetry]);

  const fetchUsers = useCallback(async () => {
    try {
      const users = await fetchWithRetry(async () => await getOrgMembers({ orgId }));
      setUsers(users);
    } catch (e) {
      handleError(e, {
        publicMessage: wrapPublicMessage(e, 'Displaying user id instead of user email'),
        publicSubject: 'Failed to fetch organization members',
      });
    }
  }, [fetchWithRetry, orgId]);

  useEffect(() => {
    fetchUsers();
  }, [orgId, fetchUsers]);

  useEffect(() => {
    fetchData();
  }, [cluster.id, orgId, fetchData]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  const columns: ColumnType<RegionalApi.ModelActionInfo>[] = useMemo(() => {
    return [
      {
        dataIndex: 'type',
        key: 'type',
        sorter: (a, b) => alphaNumericSorter(a.type, b.type),
        title: 'Type',
      },
      {
        dataIndex: 'state',
        key: 'state',
        sorter: (a, b) => alphaNumericSorter(a.state, b.state),
        title: 'State',
      },
      {
        dataIndex: 'startTime',
        key: 'startTime',
        render: (_, record) => {
          return new Date(record.startTime).toLocaleString();
        },
        sorter: (a, b) => dateTimeStringSorter(a.startTime, b.startTime),
        title: 'Start Time',
      },
      {
        dataIndex: 'owner',
        key: 'owner',
        render: (_, record) => {
          let email = '';
          users.forEach((user) => {
            if (user.id === record.owner) {
              email = user.email;
            }
          });
          return email === '' ? record.owner : email;
        },
        sorter: (a, b) => alphaNumericSorter(a.owner, b.owner),
        title: 'User',
      },
    ];
  }, [users]);

  return (
    <Modal
      size="large"
      submit={{
        handleError,
        handler: () => Promise.resolve(),
        text: 'Ok',
      }}
      title={`Cluster Action History — ${cluster.name}`}
      onClose={onClose}>
      {actions === null ? (
        <Spinner tip="Loading initial data..." />
      ) : (
        <ResponsiveTable<RegionalApi.ModelActionInfo>
          columns={columns}
          dataSource={actions}
          pagination={{ hideOnSinglePage: true }}
          rowKey="startTime"
          showSorterTooltip={false}
        />
      )}
    </Modal>
  );
};
