import { MailOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Link from 'components/Link';
import { StoreAction, useStore, useStoreDispatch } from 'contexts/Store';
import useResize from 'hooks/useResize';
import { paths } from 'routes/utils';
import { daysBefore } from 'utils/date';
const alertThreshold = 5;
const salesEmail = 'hpc-ai-gtm-sw-growth@hpe.com';

interface BannerMessageProps {
  daysLeftInTrial: number;
  openTrialEndModal: () => void;
}

const BannerMessage: React.FC<BannerMessageProps> = ({ daysLeftInTrial, openTrialEndModal }) => {
  if (daysLeftInTrial > alertThreshold) {
    return (
      <>
        <b>Welcome to your Machine Learning Development Environment Software (MLDES) free trial!</b>{' '}
        <br />
        <br />
        This full featured trial allows you to explore the capabilities of the MLDE platform managed
        within your cloud account. You have {daysLeftInTrial} days remaining in your trial. To
        upgrade your account at any time and gain access to our managed applications, please contact{' '}
        <Link path={`mailto:${salesEmail}`}>
          Sales at HPE <MailOutlined />
          &nbsp;
        </Link>{' '}
        and check out our getting started resources <Link path={paths.docs()}>here</Link>.
      </>
    );
  } else if (daysLeftInTrial >= 0) {
    const daysLeftText =
      daysLeftInTrial < 1
        ? 'Less than 1 day'
        : daysLeftInTrial === 1
        ? 'Only 1 day'
        : `Only ${daysLeftInTrial} days`;
    return (
      <>
        <b>
          {daysLeftText} remaining on your Machine Learning Development Environment Software (MLDES)
          free trial!
        </b>{' '}
        <br />
        <br />
        We hope you&apos;ve enjoyed everything the MLDES managed application experience has to
        offer. After completing your trial, your active clusters will be automatically paused, and
        you won&apos;t be able to provision or administer new clusters. Your organization access
        will also be limited. To continue using your clusters please contact{' '}
        <Link path={`mailto:${salesEmail}`}>Sales at HPE</Link> to upgrade to a paid license.
      </>
    );
  } else {
    // Trial is over
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '24px',
        }}>
        <p style={{ marginBottom: '0px', marginRight: '12px' }}>
          Your free trial has now concluded 🎉.
        </p>
        <Link onClick={openTrialEndModal}>
          Click here for <b>more</b>
        </Link>{' '}
      </div>
    );
  }
};

interface Props {
  openTrialEndModal: () => void;
}
const TrialBanner: React.FC<Props> = ({ openTrialEndModal }) => {
  const [daysLeftInTrial, setDaysLeftInTrial] = useState<number | undefined>(undefined);
  const {
    displayTrialBanner,
    orgState: { selectedOrg },
  } = useStore();
  const storeDispatch = useStoreDispatch();
  const bannerRef = useRef<HTMLDivElement>(null);
  const resize = useResize();

  useEffect(() => {
    if (
      !displayTrialBanner ||
      !selectedOrg ||
      !selectedOrg.licensingStatus.trialExpiresOn ||
      selectedOrg.licensingStatus.licenseExpiresOn
    ) {
      setDaysLeftInTrial(undefined);
    } else {
      setDaysLeftInTrial(daysBefore(new Date(selectedOrg.licensingStatus.trialExpiresOn)));
    }
  }, [displayTrialBanner, selectedOrg]);

  useLayoutEffect(() => {
    if (daysLeftInTrial) {
      const bannerElement = bannerRef.current;
      if (bannerElement) {
        document.documentElement.style.setProperty(
          '--trial-banner-height',
          `${bannerElement.getBoundingClientRect().height}px`,
        );
      }
    } else {
      document.documentElement.style.setProperty('--trial-banner-height', '0px');
    }
  }, [daysLeftInTrial, resize]);

  if (daysLeftInTrial === undefined) return null;

  const trialActive = daysLeftInTrial >= 0;

  return (
    <div ref={bannerRef}>
      <Alert
        closable={trialActive}
        message={
          <BannerMessage daysLeftInTrial={daysLeftInTrial} openTrialEndModal={openTrialEndModal} />
        }
        type={trialActive && daysLeftInTrial <= alertThreshold ? 'error' : 'info'}
        onClose={() => storeDispatch({ type: StoreAction.SetDisplayTrialBanner, value: false })}
      />
    </div>
  );
};

export default TrialBanner;
