import { ColumnType } from 'antd/es/table';

import * as GlobalApi from 'services/global-bindings';
import { alphaNumericSorter, dateTimeStringSorter } from 'utils/sort';

export const columns: ColumnType<GlobalApi.ModelInvitedUser>[] = [
  {
    dataIndex: 'email',
    defaultSortOrder: 'ascend',
    key: 'email',
    sorter: (a, b) => alphaNumericSorter(a.email, b.email),
    title: 'Email',
  },
  {
    dataIndex: 'role',
    key: 'role',
    sorter: (a, b) => alphaNumericSorter(a.role, b.role),
    title: 'Organization Role',
  },
  { dataIndex: 'defaultClusterRole', key: 'defaultClusterRole', title: 'Default Cluster Role' },
  {
    dataIndex: 'expiry',
    defaultSortOrder: 'ascend',
    key: 'expiry',
    sorter: (a, b) => dateTimeStringSorter(a.expiry, b.expiry),
    title: 'Expires',
  },
  {
    align: 'right',
    className: 'fullCell',
    fixed: 'right',
    key: 'actions',
    title: '',
    width: 40,
  },
];
