import { validate } from '@aws-sdk/util-arn-parser';
import Form from 'hew/Form';
import { Modal } from 'hew/Modal';
import Pivot from 'hew/Pivot';
import Select, { Option } from 'hew/Select';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FaAws } from 'react-icons/fa';
import { SiCheckmarx, SiGooglecloud } from 'react-icons/si';

import Link from 'components/Link';
import { useAuth } from 'contexts/Auth';
import { useStore } from 'contexts/Store';
import useConfig, { Config } from 'hooks/useConfig';
import { useFetchOrgs, useFetchWithRetry } from 'hooks/useFetch';
import { globalServerAddress } from 'routes/utils';
import { createOrg } from 'services/api';
import { globalVersion } from 'services/apiConfig';
import { ModelCreateOrgRequest, ModelCreateOrgResponse } from 'services/global-bindings';
import { Backend, Eventually, Location } from 'types';
import handleError, { ErrorLevel, wrapPublicMessage } from 'utils/error';

import {
  AwsAgentInstanceProfile,
  AwsCrossAccountRoleItem,
  AwsMasterInstanceProfile,
  GcpProjectId,
  OrgName,
} from './OrgFormItems';

interface Props {
  onClose?: () => Eventually<void>;
  onCreate?: (newOrgId: string, newOrgName: string) => Eventually<void>;
}

interface FormValues {
  agentInstanceProfile: string;
  masterInstanceProfile: string;
  name: string;
  projectId: string;
  type: string;
  xAcctRole: string;
}

interface AWSPaneProps {
  config?: Config;
  formValues: FormValues;
}

const AWSPaneContent: React.FC<AWSPaneProps> = ({ config, formValues }) => {
  const checkArn = (_: object, arn: string) => {
    if (validate(arn)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('ARN syntax is invalid'));
  };

  const selectItems = useMemo(() => {
    return (
      <>
        <Option key={Backend.EC2} value={Backend.EC2}>
          {Backend.EC2.toUpperCase()}
        </Option>
        {config?.mldmEnabled && (
          <Option key={Backend.EKS} value={Backend.EKS}>
            {Backend.EKS.toUpperCase()}
          </Option>
        )}
      </>
    );
  }, [config]);

  if (!config) return null;

  return (
    <div>
      <span>
        A{' '}
        <Link
          path={globalServerAddress(`${globalVersion}/${formValues?.type ?? 'ec2'}/cloudformation`)}
          popout={true}>
          CloudFormation template
        </Link>{' '}
        is available for creating these items. Upload it to{' '}
        <Link
          path={
            'https://us-west-2.console.aws.amazon.com/cloudformation/home?region=us-west-2#/stacks/create'
          }
          popout={true}>
          AWS CloudFormation
        </Link>{' '}
        to create the resources required here.
      </span>
      <br />
      <br />
      <div style={{ display: !config.mldmEnabled ? 'none' : undefined }}>
        <Form.Item
          extra={<div>Choose between EC2 or EKS for your infrastructure.</div>}
          label="Backend Type"
          name="type"
          required={true}>
          <Select disabled={!config.mldmEnabled}>{selectItems}</Select>
        </Form.Item>
      </div>
      <AwsCrossAccountRoleItem rules={[{ required: true, validator: checkArn }]} />
      {formValues && formValues.type === Backend.EC2 && (
        <>
          <AwsMasterInstanceProfile rules={[{ required: true, validator: checkArn }]} />
          <AwsAgentInstanceProfile rules={[{ required: true, validator: checkArn }]} />
        </>
      )}
    </div>
  );
};

const defaultTab = Location.AWS;

/**
 * A modal that gives user option to create a new
 * organization by providing a name through a form
 * and confirming.
 */
const CreateNewOrgModal: React.FC<Props> = ({ onClose, onCreate }) => {
  const [canceler] = useState(() => new AbortController());
  const fetchOrgs = useFetchOrgs(canceler);
  const [form] = Form.useForm<FormValues>();
  const formValues = Form.useWatch<FormValues>([], form);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [currentTab, setTab] = useState<Location>(defaultTab);
  const fetchWithRetry = useFetchWithRetry(canceler);
  const { doRefreshToken } = useAuth();
  const {
    orgState: { orgs },
  } = useStore();
  const config = useConfig(canceler);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsSubmitEnabled(true))
      .catch(() => setIsSubmitEnabled(false));
  }, [form, formValues, currentTab]);

  const handleSubmit = useCallback(async () => {
    const inputs = form.getFieldsValue();

    let createOrgRequest = {} as ModelCreateOrgRequest;
    if (currentTab === Location.AWS) {
      createOrgRequest = {
        backendProvider: {
          connectionInfo: {
            agentInstanceProfile: inputs.agentInstanceProfile,
            masterInstanceProfile: inputs.masterInstanceProfile,
            xAcctRole: inputs.xAcctRole,
          },
          type: inputs.type,
        },
        name: inputs.name,
      };
    } else if (currentTab === Location.GCP) {
      createOrgRequest = {
        backendProvider: { connectionInfo: { projectId: inputs.projectId }, type: Backend.GKE },
        name: inputs.name,
      };
    }
    let newOrg: ModelCreateOrgResponse | null = null;

    try {
      newOrg = await fetchWithRetry(
        async () => await createOrg(createOrgRequest, { signal: canceler.signal }),
      );
      await doRefreshToken(canceler);
      setTab(defaultTab);
      await onClose?.();
    } catch (error) {
      handleError(error, {
        level: ErrorLevel.Error,
        publicMessage: wrapPublicMessage(error, 'Failed to create organization'),
        silent: false,
      });
    }

    try {
      await fetchOrgs();
      newOrg && onCreate?.(newOrg.id, createOrgRequest.name);
    } catch (error) {
      handleError(error, {
        level: ErrorLevel.Error,
        publicSubject: 'Failed to create organization',
        silent: false,
      });
    }
  }, [form, currentTab, fetchWithRetry, doRefreshToken, canceler, onClose, fetchOrgs, onCreate]);

  const tabItems = useMemo(
    () => [
      {
        children: currentTab === Location.AWS && (
          <div>
            <br />
            <AWSPaneContent config={config} formValues={formValues} />
          </div>
        ),
        key: Location.AWS,
        label:
          currentTab === Location.AWS ? (
            <span>
              <FaAws /> AWS <SiCheckmarx />
            </span>
          ) : (
            <span>
              <FaAws /> AWS
            </span>
          ),
      },
      {
        children: currentTab === Location.GCP && (
          <div>
            <br />
            <GcpProjectId />
          </div>
        ),
        key: Location.GCP,
        label:
          currentTab === Location.GCP ? (
            <span>
              <SiGooglecloud /> GCP <SiCheckmarx />
            </span>
          ) : (
            <span>
              <SiGooglecloud /> GCP
            </span>
          ),
      },
    ],
    [config, currentTab, formValues],
  );

  return (
    <Modal
      size="large"
      submit={{
        disabled: !isSubmitEnabled,
        handleError: handleError,
        handler: handleSubmit,
        text: 'Create',
      }}
      title="Create New Organization"
      onClose={async () => {
        form.resetFields();
        setIsSubmitEnabled(false);
        setTab(defaultTab);
        await onClose?.();
      }}>
      <div>
        <Form
          form={form}
          initialValues={{ type: Backend.EC2 }}
          labelCol={{ span: 6 }}
          onFinish={handleSubmit}>
          <OrgName currentOrgs={orgs?.map((org) => org.name)} />
          <b>Backend Provider </b>
          <Pivot
            activeKey={currentTab}
            defaultActiveKey={defaultTab}
            destroyInactiveTabPane={true}
            items={tabItems}
            onChange={(e: string) => {
              setTab(e as Location);
            }}
          />
        </Form>
      </div>
    </Modal>
  );
};

export default CreateNewOrgModal;
