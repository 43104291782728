import { RegionalClusters } from 'experimental/notifications/api';
import { ModelOrgUserWithOverrides } from 'saasTypes';
import { getClusterOverrides } from 'services/api';
import * as GlobalApi from 'services/global-bindings';

export const addOverridesToMembers = async (
  members: GlobalApi.ModelOrgUser[],
  activeClusters: RegionalClusters,
  selectedOrgId: string,
  fetchWithRetry: <T>(fn: () => Promise<T>) => Promise<T>,
  canceler: AbortController,
): Promise<ModelOrgUserWithOverrides[]> => {
  const membersAndOverrides = members as ModelOrgUserWithOverrides[];

  const data = await fetchWithRetry(
    async () => await getClusterOverrides({ orgId: selectedOrgId }, { signal: canceler.signal }),
  );

  const overridesByCluster: { [key: string]: ModelOrgUserWithOverrides[] } = {};

  // filter out stale overrides; only include overrides of active clusters
  for (const region in activeClusters) {
    const activeClustersInRegion = activeClusters[region];
    activeClustersInRegion.forEach((activeCluster) => {
      if (data[activeCluster.id]) {
        const clusterID: string = activeCluster.id;
        overridesByCluster[clusterID] = data[clusterID];
      }
    });
  }

  membersAndOverrides.map((member) => {
    const memOverrides: { [key: string]: string } = {};
    for (const cluster in overridesByCluster) {
      const clusterOverrides = overridesByCluster[cluster];
      clusterOverrides.forEach((overrideUser: ModelOrgUserWithOverrides) => {
        if (overrideUser.id === member.id) {
          memOverrides[cluster] = overrideUser.role;
        }
      });
    }
    member.overrides = memOverrides;
    return member;
  });
  return membersAndOverrides;
};
