import Spinner from 'hew/Spinner';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';

import useTelemetry from 'analytics';
import Navigation from 'components/Navigation';
import { StoreAction, useStore, useStoreDispatch } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { useFetchClusters } from 'experimental/notifications/hooks';
import { notifStorage } from 'experimental/notifications/storage';
import { useFetchOrgs } from 'hooks/useFetch';
import usePolling from 'hooks/usePolling';
import useUserTheme from 'hooks/useUserTheme';
import ClusterCards from 'pages/Clusters/ClusterCards';
import LogViewPage from 'pages/Clusters/LogViewPage';
import Members from 'pages/Members/Members';
import Onboarding from 'pages/Onboarding';
import Organization from 'pages/Organization/Organization';

import { paths } from './utils';

const AuthenticatedRouter: React.FC = () => {
  const [canceler] = useState(() => new AbortController());
  const storeDispatch = useStoreDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    orgState: { orgs, hasBeenInitialized: orgChecked, selectedOrg },
  } = useStore();
  const user = useUser();
  const doFetchOrgs = useFetchOrgs(canceler);
  const doFetchClusters = useFetchClusters(canceler, { skipIfNoSubscribers: true });
  const { updateTelemetry } = useTelemetry();

  useUserTheme();
  usePolling(doFetchOrgs, { interval: 60000, runImmediately: false });
  usePolling(doFetchClusters, { continueWhenHidden: true, interval: 5000 });

  useEffect(() => {
    const orgCheck = async () => {
      await doFetchOrgs();
      const selectedOrgId = searchParams.get('orgId');
      if (selectedOrgId) {
        storeDispatch({ type: StoreAction.SetActiveOrg, value: selectedOrgId });
        searchParams.delete('orgId');
        setSearchParams(searchParams);
      }
    };
    orgCheck();
  }, [doFetchOrgs, searchParams, setSearchParams, storeDispatch]);

  useEffect(() => {
    if (!selectedOrg) return;
    notifStorage.setup(selectedOrg.id, user.userId);
    //update Telemetry on org change / user change
    updateTelemetry(selectedOrg, user);
  }, [selectedOrg, user, updateTelemetry]);

  // Abort cancel signal when app unmounts.
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  if (!orgChecked) {
    return <Spinner />;
  } else if (orgs.length === 0) {
    return <Onboarding />;
  }

  return (
    <Navigation>
      <Routes>
        <Route element={<ClusterCards />} path={paths.clusters()} />
        <Route element={<LogViewPage />} path="/clusters/:clusterId/logs" />
        <Route element={<Members />} path={paths.members()} />
        <Route element={<Organization />} path={paths.organization()} />
        <Route element={<Navigate to={paths.clusters()} />} path="*" />
      </Routes>
    </Navigation>
  );
};

export default AuthenticatedRouter;
